import React, {Component, Fragment, useEffect, useState} from "react";
import withProrodinkiService from "../../components/hoc/with-prorodinki-service";
import {baseUrl} from "../../config"
import { Button} from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {
    MDBBtn,
    MDBCol,
    MDBRow,
    MDBContainer,
    MDBCard,
    MDBCardBody,
    MDBIcon,
    MDBCardImage,
    MDBCardTitle,
    MDBCardText,
    MDBDataTable,
    MDBBtnGroup,
    MDBCardHeader
} from "mdbreact"
import {Link } from 'react-router-dom';
import ErrorCheck from "../../components/error-check";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import {Diagnoses} from "../../components/diagnoses"
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import {Layer, Rect, Stage} from "react-konva";
import URLImage from "../markobjects/UrlImage";

const VerificationCase = ({title,
                  opinions,
                  caseId,
                  caseIndex,
                  readOnly,
                  selectedDiagnose,
                  error,
                  attributes,
                  diagnoseClick,
                  images,
                  message,
                  onChange,
                  setDiagnose,
                  isolate,
                  neighborLink,
                  exclusive,
                  nnProps,
                  process,
              }) => {

    const square = (rect)=>(rect.rightX-rect.leftX)*(rect.bottomY-rect.topY)
    const [imageLoaded, setImageLoaded] = useState(false)
    const [currentMainRect, setCurrentMainRect] = useState(images[caseIndex].boundaries.sort((a,b)=>square(a)-square(b)).findIndex((el=>el.main)))
    const [currentRect, setCurrentRect] = React.useState({isDrawing:false, x:0, y:0, width:0, height:0});

    useEffect(() => {
        window.addEventListener("keydown", handleUserKeyPress);
        return () => {
            window.removeEventListener("keydown", handleUserKeyPress);
        };
    }, []);
    const imageRef = React.useRef(null);
    const handleUserKeyPress =(event) => {
        const item = Diagnoses.find(el=> el.key===event.key)
        if(!!item){
            diagnoseClick(item)
        } else{
            const arrows = ['ArrowLeft','ArrowUp','ArrowRight','ArrowDown',' ']
            const arrowIndex = arrows.findIndex(el=>el===event.key)
            if(arrowIndex>0){
                shiftMainRect(arrowIndex>1? 1: -1)
            }
        }
    };
    const startDrawRect = (e) => {
        setCurrentRect({isDrawing:true, x:e.evt.layerX, y:e.evt.layerY, width:0, height:0})
    };
    const endDrawRect = (e) => {
        setCurrentRect({isDrawing:false, x:currentRect.x, y:currentRect.y, width:e.evt.layerX - currentRect.x, height:e.evt.layerY - currentRect.y})
        images[caseIndex].boundaries.forEach((el)=>(el.main=false))
        images[caseIndex].boundaries.push({leftX:currentRect.x/imageRect().width,topY:currentRect.y/imageRect().height,
            rightX:e.evt.layerX/imageRect().width,bottomY:e.evt.layerY/imageRect().height,main:true})
        setCurrentMainRect(images[caseIndex].boundaries.sort((a,b)=>square(a)-square(b)).findIndex((el=>el.main)))
    };

    const DrawRect = (e) => {
        setCurrentRect({isDrawing:currentRect.isDrawing, x:currentRect.x, y:currentRect.y, width:e.evt.layerX - currentRect.x, height:e.evt.layerY - currentRect.y})
    };

    const confirmation = () => {
       return window.confirm("Ваше заключение не будет сохранено!")
    }
    const confirmIsolation = () => {
        return window.confirm("Обращение будет перенесено из записи о представленном новообразовании в специально созданную запись о новом новообразовании.")
    }
    const imageRect =()=> {
       return (!imageLoaded || !imageRef.current)?  null: imageRef.current.getBoundingClientRect()
    }
    const onRectDoubleClick =(e)=>{
        const point = {x:e.evt.layerX, y:e.evt.layerY}
        let current=-1
        images[caseIndex].boundaries.forEach((el,index)=>{
            const rect = {x:el.leftX*imageRect().width, y:el.topY*imageRect().height, width:(el.rightX-el.leftX)*imageRect().width, height:(el.bottomY-el.topY)*imageRect().height}
            if (isPointInRectangle(rect,point) && current===-1){
                current = index;
            }
        })
        if(current !== -1){
            images[caseIndex].boundaries.forEach((el,index)=>(el.main=(index===current)))
        }
        setCurrentMainRect(current)
    }
    const shiftMainRect = (step)=>{
        const current = images[caseIndex].boundaries.findIndex(el=>el.main)
        const next = current+step<0? images[caseIndex].boundaries.length-1:
            current+step>=images[caseIndex].boundaries.length? 0: current+step
        images[caseIndex].boundaries.forEach((el,index)=>(el.main=(index===next)))
        setCurrentMainRect(next)
    }
    const isPointInRectangle = (rect, point)=>{
        const leftX = rect.width>0? rect.x: rect.x+rect.width
        const rightX = rect.width>0? rect.x+rect.width: rect.x
        const topY = rect.height>0? rect.y: rect.y+rect.height
        const bottomY = rect.height>0? rect.y+rect.height:rect.y
        return point.x>=leftX && point.x<=rightX && point.y>=topY && point.y<=bottomY
    }

    // console.log('***images****', images)
    return <MDBContainer className="py-5" >
        <ErrorCheck error={error.isError} message={error.errorMessage}>
            <MDBRow>
                <MDBCol md="6">
                    {/*<SectionContainer header="Basic example" className="justify-content-center d-flex">*/}
                    <MDBCard className="text-center" style={{backgroundColor: `${selectedDiagnose.background}`}}>
                        <MDBCardTitle className="pt-3">
                            {title}
                        </MDBCardTitle>
                        <MDBCardBody className='text-center'>
                            <div style={{position:'absolute', zIndex: 100}}>
                                {imageLoaded ?
                                    <Stage width={500} height={imageRect().height>500? imageRect().height: 500}>
                                        <Layer>
                                            {
                                                images[caseIndex].boundaries.map((rect, index) =>
                                                <Rect
                                                    key={index}
                                                    x={rect.leftX * imageRect().width}
                                                    y={rect.topY * imageRect().height}
                                                    width={(rect.rightX - rect.leftX) * imageRect().width}
                                                    height={(rect.bottomY - rect.topY) * imageRect().height}
                                                    stroke={rect.main ? "red" : "green"}
                                                    onDblClick={onRectDoubleClick}
                                                />)
                                            }
                                            { currentRect.isDrawing?
                                                <Rect
                                                    x={currentRect.x}
                                                    y={currentRect.y}
                                                    width={currentRect.width}
                                                    height={currentRect.height}
                                                    stroke="red"
                                                />:null
                                            }
                                            <Rect
                                                width={imageRect().width}
                                                height={imageRect().height}
                                                onMouseDown={startDrawRect}
                                                onMouseUp={endDrawRect}
                                                onMouseMove={currentRect.isDrawing? DrawRect: ()=>{}}
                                                onDblClick={onRectDoubleClick}
                                            />

                                        </Layer>
                                    </Stage> : null
                                }
                            </div>
                            <div >
                                <img ref={imageRef} src={images[0].src} style={{width:'100%', visibility: imageLoaded?'visible':'hidden'}} className="img-fluid" alt="..."
                                     onLoad={()=>setImageLoaded(true)}
                                />
                            </div>
                            <MDBDataTable
                                autoWidth
                                responsive={true}
                                info={false}
                                hover
                                noBottomColumns
                                sortable={false}
                                data={attributes()}
                                paging={false}
                                noRecordsFoundLabel="Данных не обнаружено"
                                searching={false}
                            />
                            {   process !== "VERIFICATION"?
                                <Button color="primary" className="pt-3" disabled={images.length < 2} onClick={() => {
                                    let call = true
                                    if (selectedDiagnose.id !== null) {
                                        call = confirmation();
                                    }
                                    if (call) {
                                        call = confirmIsolation()
                                        if (call) {
                                            isolate.onClick()
                                        }
                                    }
                                }}>
                                    Перенести из новообразования
                                </Button>:null
                            }
                        </MDBCardBody>
                    </MDBCard>
                    {/*</SectionContainer>*/}
                </MDBCol>
                <MDBCol md="6">
                    {    process !== "VERIFICATION"?
                        <MDBContainer className="text-left">
                            <h5> Имеющиеся заключения: </h5>
                            <MDBDataTable
                                autoWidth
                                responsive={true}
                                info={false}
                                hover
                                noBottomColumns
                                sortable={false}
                                data={opinions()}
                                paging={false}
                                noRecordsFoundLabel="Данных не обнаружено"
                                searching={false}
                            />
                        </MDBContainer>:null
                    }
                    <MDBContainer className="text-left">
                        <MDBRow>
                        <MDBCol md="6">
                           <h5> Ваше заключение: </h5>
                            <MDBBtnGroup vertical size="sm">
                                {Diagnoses.map(item => <MDBBtn key={item.id} outline={item.id !== selectedDiagnose.id}
                                            className="mt-3" color={readOnly? "#111111": item.color} onClick={() => {
                                        diagnoseClick(item)
                                    }} disabled={readOnly}>
                                        <strong>{item.name+' ('+item.key+')'}</strong>
                                    </MDBBtn>)
                                }
                            </MDBBtnGroup>
                        </MDBCol>
                            {process !== "VERIFICATION" ?
                                <MDBCol md="6">
                                    <FormControl variant="standard" sx={{ m: 1, minWidth: 200 }}>
                                        <InputLabel id="message_select_label">Сообщение пользователю</InputLabel>
                                        <Select
                                            labelId="message_select_label"
                                            id="message_select"
                                            value={message.messageSelectValue}
                                            onChange={message.messageSelectValueChange}
                                        >
                                            <MenuItem key={0} value={0}>
                                                <em>Без сообщений</em>
                                            </MenuItem>

                                            {message.list.map(item => {
                                                return (
                                                    <MenuItem key={item.id} value={item.id}>
                                                        {item.name}
                                                    </MenuItem>
                                                )
                                            })
                                            }
                                        </Select>
                                    </FormControl>
                                    <textarea value={message.getMessageText()}
                                              rows="7" cols="40"
                                              disabled={false}
                                              onChange={(event) => message.messageTextPersist(event)}
                                    />
                                </MDBCol>:null
                            }
                        </MDBRow>
                    </MDBContainer>
                    {process !== "VERIFICATION" ?
                        <MDBContainer className="mt-2 text-left">
                            <FormControlLabel
                                checked={exclusive.mode}
                                control={<Checkbox color="primary"/>}
                                onChange={exclusive.change}
                                label="Диагностирование"
                                labelPlacement="end"
                            />
                            <FormControlLabel
                                checked={nnProps.nnSuitable}
                                control={<Checkbox color="primary"/>}
                                onChange={nnProps.changeNnSuitable}
                                label="Для обучения нейросети"
                                labelPlacement="end"
                            />

                        </MDBContainer>:null
                    }
                    <MDBContainer className="mt-2 text-left">
                        <MDBBtnGroup className={"align-items-center"}>
                            {process !== "VERIFICATION" ?
                                <MDBIcon
                                    icon="arrow-left" size="2x" className="mr-3 text-primary"
                                    onClick={() => {
                                        let call = true
                                        if (selectedDiagnose.id !== null) {
                                            call = confirmation();
                                        }
                                        if (call) {
                                            neighborLink(-1)
                                        }
                                    }}
                                />:null
                            }
                            <MDBBtn color="primary" size={process !== "VERIFICATION" ? "sm":"md"} disabled={readOnly || selectedDiagnose.id === null}
                                    onClick={() => setDiagnose(caseId,selectedDiagnose.id, 1)}>
                                {process !== "VERIFICATION" ?'Этот кейс':"Подтвердить"} <MDBIcon icon="check" />
                            </MDBBtn>
                            {process !== "VERIFICATION" ?
                                <MDBBtn color="primary" size="sm" disabled={readOnly || selectedDiagnose.id === null}
                                        className="ml-3"
                                        onClick={() => setDiagnose(caseId, selectedDiagnose.id, 1, "neoplasm")}>
                                    Этот и все после <MDBIcon icon="check-double"/>
                                </MDBBtn>:null
                            }
                            {process !== "VERIFICATION" ?
                                <MDBIcon
                                    icon="arrow-right" color="blue" size="2x" className="ml-2  text-primary"
                                    onClick={() => {
                                        let call = true
                                        if (selectedDiagnose.id !== null) {
                                            call = confirmation();
                                        }
                                        if (call) {
                                            neighborLink(1)
                                        }
                                    }}
                                />:null
                            }
                        </MDBBtnGroup>
                    </MDBContainer>
                </MDBCol>
            </MDBRow>
        </ErrorCheck>
    </MDBContainer>;

};
export default VerificationCase
