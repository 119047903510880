import React, {useState} from "react";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import Evidence from "./Evidence";
import FormGroup from "@mui/material/FormGroup";
import TextField from '@mui/material/TextField';
import {CaseControl} from "./Controls"
import Box from "@mui/material/Box";


const Recommendation = (props) => {
    const {expertOpinion, secondaryControls, selectedDiagnose, noMessage} = props
    const controls = {
        choose_specialist:{
            dermatologist:'Дерматолог',
            oncologist:'Онколог',
            no_consultancy: 'Не требуется'
        },
        dermatoscopy_period:{
            onetime_dermatoscopy:'Однократно',
            quarterly_dermatoscopy:'Три месяца',
            semiannual_dermatoscopy:'Полгода',
            yearly_dermatoscopy:'Ежегодно',
            no_dermatoscopy:"Не требуется"
        }
    }

    const isChecked = (value)=>{
        return !!secondaryControls.isChecked(value)
    }

    const onRadioGroupCheck = (event,value)=>{
        controlCheck(value,true)
        for (const key of Object.keys(controls[event.target.name])) {
            if (key!==value){
                controlCheck(key,false)
            }
        }
    }
    const controlCheck = (value,checked)=>{
        secondaryControls.check(value, checked)
    }

    const checkedRadioValue = (group)=>{
        for (const key of Object.keys(controls[group])) {
            if (secondaryControls.isChecked(key)) return key
        }
        return ''
    }
    const onDermatoscopyChange = (event)=>{
        controlCheck(event.target.value,event.target.checked)
    }

    const onClinicalInfoChange = (event)=>{
        // console.log("onClinicalInfoChange event.target.value",event.target.value)
        // console.log("onClinicalInfoChange event.target.checked",event.target.checked)
        controlCheck(event.target.value,event.target.checked)
        expertOpinion.setRecommendation(selectedDiagnose)
    }
    const onServiceImpossible = (event)=>{
        controlCheck(event.target.value,event.target.checked)
    }

    const CustRadioGroup =({group})=>{
        return (
            <RadioGroup  name={group} row onChange={onRadioGroupCheck} value={checkedRadioValue(group)}>
                {Object.keys(controls[group]).map((el)=> (<FormControlLabel disabled={secondaryControls.isDisabled(el)} key={el} value={el} control={<Radio/>} label={controls[group][el]}/>))}
            </RadioGroup>
        )
    }
    const noMessageCheck = (event)=>{
        noMessage.noMessageCheck(event.target.checked)
    }
    const isNoMessageChecked = ()=>{
        return noMessage.checked
    }
    const formatSigns = (jsonSigns)=>{
        console.log("jsonSigns", jsonSigns)
        const groups=[]
        const signs = JSON.parse(jsonSigns)
        Object.keys(signs).forEach(key=> groups.push(key+': '+String(signs[key])))
        return groups.join('. ')
    }
    return (
        <Box sx={{display:'flex',flexDirection:'column'}}>
            <FormGroup row>
                <FormControlLabel value={'clinical_info'} control={<Checkbox/>} disabled={secondaryControls.isDisabled('clinical_info')} checked={isChecked('clinical_info')} onChange ={onClinicalInfoChange} label='Недостаточно клинической информации'/>
                <FormControlLabel value={'sevice_impossible'} control={<Checkbox/>} checked={isChecked('sevice_impossible')} onChange ={onServiceImpossible} label='Рекомендация невозможна'/>
            </FormGroup>
            <FormControl sx={{mb:1, borderBottom: 1,}}>
                <FormLabel  color='primary' id={'specialist'}>Консультация специалиста</FormLabel>
                <CustRadioGroup group={'choose_specialist'}/>
            </FormControl>
            <FormControl sx={{mb:1, borderBottom: 1,}}>
                <FormLabel  color='primary' id={'dermatoscopy'}>Дерматоскопия. Период иследования:</FormLabel>
                <CustRadioGroup group={'dermatoscopy_period'}/>
            </FormControl>
            <FormControl sx={{mb:1, borderBottom: 1,}}>
                <FormControlLabel value={'photo_finder'} control={<Checkbox/>} label='Фотокартирование' disabled={secondaryControls.isDisabled('photo_finder')}/>
            </FormControl>

            <FormControlLabel sx={{mt:3}} value={'no_message'} control={<Checkbox/>} label='Без сообщений' disabled={noMessage.disabled} checked={isNoMessageChecked()} onChange={noMessageCheck}/>

            <TextField id='signstext-label'
                label="Анализ изображения:"
                multiline
                value={!!expertOpinion.signs?formatSigns(expertOpinion.signs):""}
                variant="standard" disabled={true}
            />
            <TextField sx={{mt:1}} id='conclusion-label'
                       label="Вывод:"
                       multiline
                       value={!!expertOpinion.status?expertOpinion.status:""}
                       onChange={(e) => {expertOpinion.saveRecommendation('status',e.target.value) }}
                       variant="standard" disabled={isNoMessageChecked()}
            />
            <TextField sx={{mt:1}} id='recommendation-label'
                       label="Рекомендация:"
                       multiline
                       value={!!expertOpinion.todo?expertOpinion.todo:""}
                       onChange={(e) => {expertOpinion.saveRecommendation('todo',e.target.value) }}
                       variant="standard" disabled={isNoMessageChecked()}
            />
            <TextField sx={{mt:1}} id='before-label'
                       label="Перед визитом к врачу:"
                       multiline
                       value={!!expertOpinion.before?expertOpinion.before:""}
                       onChange={(e) => {expertOpinion.saveRecommendation('before',e.target.value) }}
                       variant="standard" disabled={isNoMessageChecked()}
            />
        </Box>
    )
}
export default Recommendation
