import ErrorCheck from "../../components/error-check";
import {MDBCard, MDBCardBody, MDBCardTitle, MDBCol, MDBContainer, MDBRow} from "mdbreact";
import React, {useEffect, useState} from "react";
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {Diagnoses} from "../../components/diagnoses";
import {withRouter} from "react-router-dom"
import withProrodinkiService from "../../components/hoc/with-prorodinki-service";
import {baseUrl} from "../../config";


function  Arbitrage(props) {
    let initialDiagnosis = localStorage.getItem('Arbitrage\initialDiagnosis')===null?"All": localStorage.getItem('Arbitrage\initialDiagnosis')
    const [diagnoseId, setDiagnoseId] = useState(initialDiagnosis)
    const [page, setPage] = useState('1')
    const [linesPerPage, setLinesPerPage] = useState('20')
    const [tileData, setTileData] = useState([])
    const [loadingStatus, setLoadingStatus] = useState(true)
    const [error, setError] = useState({isError:false, errorMessage:""})

    const getImageListFromServer = async  (diagnoseId, page, linesPerPage) =>
    {
        setLoadingStatus(true)
        let response = await (props.prorodinkiService.getArbitrageImages(diagnoseId, linesPerPage*(page-1), linesPerPage));
        processResponse(response)
    }

    useEffect(  () => {
            getImageListFromServer(diagnoseId, page, linesPerPage).then(()=> {} )
        },
        [diagnoseId, page, linesPerPage]
    )
    const processResponse  = (response) => {
        if (!response.hasError && response.data === "") {
            response.hasError = true
            response.error.message = "Данные не обнаружены"
        }
        if (response.hasError) {
            setError({isError:true, errorMessage:response.error.message})
        } else {
            // console.log('processResponse Response:', response)
            setError({isError:false, errorMessage:""})
            setTileData(response.data)
            props.prorodinkiService.arbitrageList=response.data

        }
        setLoadingStatus(false)

        return (!response.hasError)
    }

    return (
            <MDBRow>
                <MDBCol md="9">
                    <MDBCard className="text-center" >
                         <MDBCardBody className='text-left'>
                             <MDBCol>
                                 <MDBContainer className="py-3">
                                     <FormControl variant="standard" sx={{ m: 1, minWidth: 200 }}>
                                         <InputLabel id="diagnosis_label">Заключение:</InputLabel>
                                         <Select
                                             labelId="diagnosis_label"
                                             id="select_diagnosis"
                                             value={diagnoseId}
                                             onChange={event=>{
                                                 setDiagnoseId(event.target.value)
                                                 localStorage.setItem('Arbitrage\initialDiagnosis',event.target.value)
                                                 }}
                                         >
                                             <MenuItem key={"All"} value={"All"}>
                                                 <em>Все</em>
                                             </MenuItem>
                                             {Diagnoses.map(item => {
                                                 return (
                                                     <MenuItem key={item.id} value={item.id}>
                                                         {item.name}
                                                     </MenuItem>
                                                 )
                                             })
                                             }
                                         </Select>
                                     </FormControl>

                                 </MDBContainer>
                                <div >
                                    <ImageList rowHeight={160} cols={4}>
                                        {tileData.map((tile) => {
                                            return (
                                            <ImageListItem key={tile.imgName} cols={tile.cols || 1}>
                                                <img src={`${baseUrl}/img/${tile.imgName}`} onClick={() => {props.history.push(`/arbitrage/${tile.caseId}`)}} alt={tile.caseId} style={{width:160,height:160}}/>
                                                <ImageListItemBar style={{width:160, height:14}}
                                                    subtitle={<span>{`${tile.diagnoseId1} / ${tile.diagnoseId2}`}</span>}
                                                />
                                            </ImageListItem>
                                        )})}
                                    </ImageList>
                                </div>
                             </MDBCol>
                        </MDBCardBody>
                    </MDBCard>
                </MDBCol>
            </MDBRow>
    );

}
export default withRouter(withProrodinkiService(Arbitrage))
