import React, { Component } from 'react';
import {
    MDBNavbar,
    MDBNavbarBrand,
    MDBNavbarNav,
    MDBNavbarToggler,
    MDBCollapse,
    MDBNavItem,
    MDBFooter,
    MDBNavLink,
    MDBDropdown,
    MDBDropdownToggle,
    MDBIcon,
    MDBDropdownMenu,
    MDBDropdownItem,
} from 'mdbreact';
import logo from "../../logo_contour_white.png";
import { BrowserRouter as Router } from 'react-router-dom';
import Routes from '../routes';
import withProrodinkiService from "../hoc/with-prorodinki-service";

class NavBar extends Component {
    state = {
        collapseID: '',
    };

    toggleCollapse = collapseID => () =>
        this.setState(prevState => ({
            collapseID: prevState.collapseID !== collapseID ? collapseID : ''
        }));

    closeCollapse = collapseID => () => {
        window.scrollTo(0, 0);
        this.state.collapseID === collapseID && this.setState({ collapseID: '' });
    };
    getMainPath=()=>{
        if(this.props.user.roles.includes("expert")) return "/"
        if(this.props.user.roles.includes("super")) return "/"
        if(this.props.user.roles.includes("admin")) return "/admin"
        if(this.props.user.roles.includes("student")) return "/exam"
        if(this.props.user.roles.includes("marker")) return "/markobjects"
        if(this.props.user.roles.includes("consultant")) return "/consultation"
    }

    hasPermission=(action)=>{
        switch (action){
            case "CASELIST":
                return this.allowedFor(['super','expert', 'admin'])
            case "CASE":
                return this.allowedFor(['super','expert', 'admin'])
            case "VERIFICATION":
                return this.allowedFor(['super', 'admin'])
            case "VALIDATION":
                return this.allowedFor(['super', 'admin'])
            case "MARKUPS":
                return this.allowedFor(['marker', 'admin'])
            case "EXAM":
                return this.allowedFor(['super', 'expert', 'admin', 'student', 'consultant'])
            case "CONSULTATION":
                return this.allowedFor(['consultant'])
            case "ADMINISTRATOR":
                return this.allowedFor(['admin'])
        }
    }

    allowedFor= (roles)=>roles.some((role) =>this.props.user.roles.includes(role))

    render() {
        const overlay = (
            <div
                id='sidenav-overlay'
                style={{ backgroundColor: 'transparent' }}
                onClick={this.toggleCollapse('mainNavbarCollapse')}
            />
        );

        const { collapseID } = this.state;

        return (
                <Router>
                  <div className='flyout'>
                    <MDBNavbar color='unique-color' dark expand='md' fixed='top' scrolling>
                        <MDBNavbarBrand href={this.getMainPath()} className='py-0 font-weight-bold'>
                            <img src={logo} alt="logo" className='py-0 my-0' style={{width: "4rem"}}/>
                            <strong className='pl-2 align-middle'>PROrodinki</strong>
                        </MDBNavbarBrand>
                        <MDBNavbarToggler
                            onClick={this.toggleCollapse('mainNavbarCollapse')}
                        />
                        <MDBCollapse
                            id='mainNavbarCollapse'
                            isOpen={this.state.collapseID}
                            navbar
                        >
                            <MDBNavbarNav left className='pl-5'>
                                {this.hasPermission('CASELIST') ?
                                    <MDBNavItem>
                                        <MDBNavLink
                                            exact
                                            to='/'
                                            onClick={this.closeCollapse('mainNavbarCollapse')}
                                        >
                                            <MDBIcon icon="list-alt" className="mr-2"/>
                                            <strong>Обращения</strong>
                                        </MDBNavLink>
                                    </MDBNavItem>:null
                                }
                                {this.hasPermission('VALIDATION') ?
                                    <MDBNavItem>
                                        <MDBNavLink
                                            exact
                                            to='/validation'
                                            onClick={this.closeCollapse('mainNavbarCollapse')}
                                        >
                                            <MDBIcon icon="list-alt" className="mr-2"/>
                                            <strong>Валидация</strong>
                                        </MDBNavLink>
                                    </MDBNavItem>:null
                                }
                                { this.hasPermission('VERIFICATION')?
                                    <MDBNavItem>
                                        <MDBDropdown>
                                            <MDBDropdownToggle nav caret>
                                                <MDBIcon icon="chalkboard-teacher" className="mr-2"/>
                                                <strong>Экспертиза</strong>
                                            </MDBDropdownToggle>
                                            <MDBDropdownMenu className="dropdown-default" >
                                                <MDBDropdownItem>
                                                    <MDBNavLink className="blue-grey"
                                                        onClick={this.closeCollapse('mainNavbarCollapse')}
                                                        to='/verification'
                                                    >
                                                        <MDBIcon icon="microscope" className="mr-2"/>
                                                        <strong>Верификация</strong>
                                                    </MDBNavLink>
                                                </MDBDropdownItem>
                                                <MDBDropdownItem>
                                                    <MDBNavLink className="blue-grey"
                                                        onClick={this.closeCollapse('mainNavbarCollapse')}
                                                        to='/arbitrage'
                                                    >
                                                        <MDBIcon icon="balance-scale" className="mr-2"/>
                                                        <strong>Арбитраж</strong>
                                                    </MDBNavLink>
                                                </MDBDropdownItem>
                                            </MDBDropdownMenu>
                                        </MDBDropdown>

                                    </MDBNavItem>:null
                                }
                                {this.hasPermission('MARKUPS') ?
                                    <MDBNavItem>
                                        <MDBNavLink
                                            onClick={this.closeCollapse('mainNavbarCollapse')}
                                            to='/markobjects'
                                        >
                                            <MDBIcon icon="marker" className="mr-2"/>
                                            <strong>Разметка</strong>
                                        </MDBNavLink>
                                    </MDBNavItem> : null
                                }
                                {this.hasPermission('CONSULTATION')?
                                    <MDBNavItem>
                                        <MDBNavLink
                                            onClick={this.closeCollapse('mainNavbarCollapse')}
                                            to='/consultation'
                                        >
                                            <MDBIcon icon="check-double" className="mr-2"/>
                                            <strong>Консультация</strong>
                                        </MDBNavLink>
                                    </MDBNavItem>:null
                                }
                                {this.hasPermission('EXAM')?
                                    <MDBNavItem>
                                        <MDBNavLink
                                            onClick={this.closeCollapse('mainNavbarCollapse')}
                                            to='/exam'
                                        >
                                            <MDBIcon icon="check-double" className="mr-2"/>
                                            <strong>Экзамен</strong>
                                        </MDBNavLink>
                                    </MDBNavItem>:null
                                }
                                {this.hasPermission('ADMINISTRATOR') ?
                                    <MDBNavItem>
                                        <MDBNavLink
                                            onClick={this.closeCollapse('mainNavbarCollapse')}
                                            to='/admin'
                                        >
                                            <MDBIcon icon="users-cog" className="mr-2"/>
                                            <strong>Администратор</strong>
                                        </MDBNavLink>
                                    </MDBNavItem> : null
                                }
                            </MDBNavbarNav>>
                            <MDBNavbarNav right>
                                <MDBNavItem>
                                    <MDBNavLink
                                        onClick={this.closeCollapse('mainNavbarCollapse')}
                                        to='/profile'
                                    >
                                            <MDBIcon icon="user-md" className="mr-2"/>
                                        <strong>{this.props.user.fio}</strong>
                                    </MDBNavLink>
                                    </MDBNavItem>
                                <MDBNavItem>
                                    <MDBNavLink
                                        onClick={this.props.signOut}
                                        to="/signout"
                                    >
                                        <MDBIcon icon="sign-out-alt" className="mr-2"/>
                                        <strong>Выход</strong>
                                    </MDBNavLink>
                                </MDBNavItem>
                            </MDBNavbarNav>
                        </MDBCollapse>
                    </MDBNavbar>
                    {collapseID && overlay}
                    <main style={{marginTop: '4rem'}}>
                        <Routes user={this.props.user} mainPath={this.getMainPath()} hasPermission={this.hasPermission}/>
                    </main>
                    <MDBFooter color='unique-color'>
                        <p className='footer-copyright mb-0 py-3 text-center'>
                            &copy; {new Date().getFullYear()} Copyright:
                            <a href='https://www.prorodinki.ru'> Prorodinki.com </a>
                        </p>
                    </MDBFooter>
                  </div>
                </Router>
        );
    }
}

export default withProrodinkiService(NavBar);
