import React, {Component, Fragment, useEffect, useState} from "react";
import {withRouter} from "react-router-dom"
import withProrodinkiService from "../../components/hoc/with-prorodinki-service";
import {baseUrl} from "../../config"
import Case from "./case";
import {MDBIcon, MDBCol} from "mdbreact";
import {Disabled_if,CaseControl} from "./Controls"
import Evidence from "./Evidence"
import {Diagnoses} from "../../components/diagnoses";

const CaseController = props =>{
    //console.log("entered with props ", props)
    const initialDiagnose = {id: null, background:"#888888"}
    const initialEvidence = ()=>{
        const obj={}
        Object.keys(Evidence).forEach(key=>{obj[key]={}})
        return obj
    }

    const [neoplasmData, setNeoplasmData] = useState(null)
    const [loadingStatus, setLoadingStatus] = useState(true)
    const [error, setError] = useState({isError:false, errorMessage:null})
    const [selectedDiagnose, setSelectedDiagnose] = useState(initialDiagnose)
    const [newCaseTrigger, setNewCaseTrigger] = useState(false)
    const [currentIndex, setCurrentIndex] = useState(0)
    const [expertOpinion, setExpertOpinion] = useState({signs:"", status:"", todo:"", before:""})
    const [currentCaseId, setcurrentCaseId] = useState(props.caseId)
    const [opinionConsructor, setOpinionConsructor] = useState([])
    const [secondaryChecked, setSecondaryChecked]= useState({dependants:{}, master:{}})
    const [evidenceChecked, setEvidenceChecked]= useState(initialEvidence())
    const [disabled, setDisabled]= useState({})
    const [tabsValue, setTabsValue] = useState('conclusions')
    const [noMessage, setNoMessage] = useState({checked:false,disabled:false})

    let caseParams = localStorage.getItem('CaseParams')===null?{
        exclusiveMode:true,
    }: JSON.parse(localStorage.getItem('CaseParams'));
    const [exclusiveMode, setExclusiveMode] = useState(caseParams.exclusiveMode)
    const [nnSuitable, setNnSuitable] = useState(false)

    const getCaseFromServer = async  () =>
    {
        setLoadingStatus(true)
       let response = await (props.prorodinkiService.getCase(currentCaseId, exclusiveMode));
        processResponse(response)
    }

    const putCaseMove = async  (increment) =>
    {
        setLoadingStatus(true)

        let caseIds = null
        if(increment!==0) {
            const indexInCaseList= props.prorodinkiService.caseList.findIndex(caseObject => caseObject.id===currentCaseId)
            if(increment > 0) {
                caseIds = props.prorodinkiService.caseList.slice(indexInCaseList+1).map(object => object.id)
            }
            else{
                caseIds = props.prorodinkiService.caseList.slice(0,indexInCaseList).map(object => object.id).reverse()
            }
            if (caseIds.length === 0) caseIds=null
        }
        let response = await (props.prorodinkiService.putCaseMove(currentCaseId, caseIds,  exclusiveMode));
        processResponse(response)

    }

    useEffect(  () => {
            getCaseFromServer().then(()=> {} )
        },
        [newCaseTrigger,props.caseId]
    )

    const setDiagnose = async (caseId, diagnosis, mode="case") => {
        // console.log('setDiagnose noMessage',noMessage)
        // console.log('setDiagnose expertOpinion',expertOpinion)
        const response = await(props.prorodinkiService.putDiagnose(caseId, diagnosis, !noMessage.checked? expertOpinion:{signs:"",status:"",todo:"",before:""}));
        if(processResponse(response) ){
            setNewCaseTrigger(!newCaseTrigger)
        }

    }

    const changeExclusiveMode = (e) =>{
        caseParams.exclusiveMode = (e.target.checked)
        setExclusiveMode(caseParams.exclusiveMode)
        localStorage.setItem('CaseParams', JSON.stringify(caseParams));
    }
    const changeNnSuitable = (e) =>{
        setNnSuitable(e.target.checked)
        props.prorodinkiService.putNnSuitable(currentCaseId, e.target.checked)
    }

    const processResponse  = (response) => {
        if (!response.hasError && response.data === "") {
            response.hasError = true
            response.error.message = "Данные не обнаружены"
        }
        if (response.hasError) {
            setError({isError:true, errorMessage:response.error.message})
            setSelectedDiagnose({id: null, background:null})
            setNeoplasmData(null)
            //console.log('Error returned:', response.error)
        } else {
            //console.log('processResponse Response:', response)
            response.data.caseImages.forEach(caseImage=>{
                caseImage.opinions.sort((op1,op2)=>{return (op1.date-op2.date)})
                const nnOpinions = caseImage.opinions.filter(opinion=>(!!opinion.nnResults))
                caseImage.nnResults = {"diagnosis":"","confidence":0.0,"caseObjects":[]}
                if ((nnOpinions.length) >0){
                    caseImage.nnResults = JSON.parse(nnOpinions[nnOpinions.length-1].nnResults)
                }
                // console.log("caseImage.nnResults",caseImage.nnResults)

            })
            setError({isError:false, errorMessage:null})
            setSelectedDiagnose(initialDiagnose)
            setCurrentIndex(response.data.currentIndex)
            setcurrentCaseId(response.data.caseImages[response.data.currentIndex].id)
            setNnSuitable(response.data.caseImages[response.data.currentIndex].nnSuitable)
            setNeoplasmData(response.data)
            setNoMessage(initNoMessage(response.data))
            setOpinionConsructor(getOpinionConstructor(response.data.opinionConstructors))
            completeExpertOpinion({signs:"", status:"", todo:"", before:""})
            setTabsValue("conclusions")
            setSecondaryChecked({dependants:{}, master:{}})
            setDisabled({})
         }
        setLoadingStatus(false)
        return (!response.hasError)
    }
    const initNoMessage = (data=neoplasmData, diag=selectedDiagnoseItem())=>{
        const noMessage= {checked : !!(hasExpertOpinion(data)) || (!expertOpinionRequired(data) && (neuroOpinionConfirmed(diag) || !isNeuroOpinionFinal())),
            disabled : expertOpinionRequired(data)
        }
        // console.log("initNoMessage noMessage",noMessage)
        return {...noMessage}
    }
    const noMessageCheck = (checked)=>{
        // console.log("noMesageCheck checked", checked)
        // console.log("noMesageCheck noMessage", noMessage)
        setNoMessage((prev)=>({...prev,checked: checked}))
        if(checked){
            emptyMessage()
        } else{
            setRecommendation()
        }
    }

    const getNnResults = ()=> {
        return neoplasmData.caseImages[currentIndex].nnResults;
    }

    const getOpinionConstructor = (opinionConstructors)=>{
        const constructor= {}
        constructor['PRIMARY']={}
        constructor['SECONDARY']={}
        constructor['ADDITION']={}
        opinionConstructors.forEach(el=>{
            el.dependants = (!!el.dependants)? JSON.parse(el.dependants):{}
            const {dependants,status,todo,before} = el
            constructor[el.type][el.key]={dependants,status,todo,before}
        })
        return constructor
    }


    const indexOfCaseId = (caseObjects, caseId) => caseObjects.findIndex(caseObject => caseObject.id===caseId)

    const getNeighborRef = (incr)=>{
        putCaseMove(incr).then(()=> {} )
    }

    const attributes = () => {
        return ({
            columns: [
                {
                    label: "Возраст",
                    field: "age",
                },
                {
                    label: "Пол",
                    field: "sex",
                },
                {
                    label: "Локализация",
                    field: "location",
                },
                {
                    label: "Когда возникло",
                    field: "beginning",
                },
            ],
            rows: neoplasmData===null? []: [
                {
                    age: new Date().getFullYear() - neoplasmData.patient.year,
                    sex: neoplasmData.patient.sex? "Женский": "Мужской",
                    location: neoplasmData.location.description,
                    beginning: neoplasmData.beginning.description
                },
            ],
        })};

    const patientProps ={
        columns: [
            {
                label: "Свойство",
                field: "property",
            },
            {
                label: "Значение",
                field: "value",
            },
        ],
        rows: !neoplasmData || !neoplasmData.patient.profile? []: neoplasmData.patient.profile.map(el=>({...el}))
    }

    const diagnoseClick = (item) => {
        setSelectedDiagnose(item)
        setNoMessage(initNoMessage(neoplasmData,item))
        setRecommendation(item)
        setTabsValue( defineTabsValue(item))
    }
    const defineTabsValue = (item) =>{
        if (tabsValue!=="conclusions") return tabsValue
        if (item.type==="UNRECOGNIZED" || item.type==="OUT_OF_SCOPE") return "recommendation"
        return expertOpinionRequired()?"analyse":"recommendation"
    }
    const selectedDiagnoseItem = ()=>Diagnoses.find(diagnose=>diagnose.id===selectedDiagnose.id)
    const setRecommendation = (diag=selectedDiagnose)=>{
        // console.log("setRecommendation diag",diag)
        const key = diag.type + (isSecondaryChecked('clinical_info')?'-TRUE':'-FALSE')
        // console.log("setRecommendation key",key)
        const checkedControls = secondaryChecked
        checkedControls['dependants'] = opinionConsructor['PRIMARY'][key].dependants
        // console.log("setRecommendation checkedControls",checkedControls)
        setSecondaryChecked(checkedControls)
        let tempExpertOpinion={...expertOpinion, ...opinionConsructor['PRIMARY'][key]}
        setDisabled(defineDisabledControls(checkedControls))
        tempExpertOpinion = setDependantsText(tempExpertOpinion, checkedControls, defineDisabledControls(checkedControls))
        completeExpertOpinion(tempExpertOpinion,diag)
    }
    const emptyMessage=()=>{
        // console.log("Empty message")
        setExpertOpinion({signs:"", status:"", todo:"", before:""})
    }
    const completeExpertOpinion = (tempExpertOpinion,diag=selectedDiagnose)=>{
        if (noMessage.checked){
            emptyMessage()
        }
        tempExpertOpinion.status = completeStatus(tempExpertOpinion.status,diag)
        setExpertOpinion(tempExpertOpinion)
    }
    const completeStatus = (status,diag) =>{
        const confirmation = neuralOpinionConfirmMessage(diag)
        return (!!confirmation? confirmation+"\n":"") + status
    }
    const neuroOpinionConfirmed = diag =>{
        if (!diag) return false
        if(!neoplasmData || !neoplasmData.caseImages || !neoplasmData.caseImages[currentIndex]) return false
        const neuroOp = neuroOpinion(neoplasmData.caseImages[currentIndex])
        if(!!neuroOp){
            const neuroDiag = Diagnoses.find(diagnose =>diagnose.id===neuroOp.diag.diagnosis)
            return neuroDiag.type===diag.type
        }
        return false;
    }
    const isNeuroOpinionFinal = () =>{
        if(!neoplasmData || !neoplasmData.caseImages || !neoplasmData.caseImages[currentIndex]) return false
        const neuroOp = neuroOpinion(neoplasmData.caseImages[currentIndex])
        return !!neuroOp && neuroOp.finalOpinion
    }
    const neuralOpinionConfirmMessage = diag =>{
        return !isNeuroOpinionFinal()?"":
            neuroOpinionConfirmed(diag)? "Заключение нейросети подтверждается": ((!diag || diag.type==="UNRECOGNIZED" || diag.type==="OUT_OF_SCOPE")?"":"Заключение нейросети не подтверждается")
    }

    const defineDisabledControls = (checkedControls=secondaryChecked)=>{
        let disabledControls ={}
        const checkedKeys=([...Object.keys(checkedControls['master']).filter(key=>!!checkedControls['master'][key]),
            ...Object.keys(checkedControls['dependants']).filter(key=>!!checkedControls['dependants'][key])])
        for (const master of checkedKeys){
            if(!!Disabled_if[master]){
                for(const slave of Disabled_if[master]){
                    disabledControls[slave]=true
                }
            }
        }
        return disabledControls
    }
    const isDisabled = control=>!!disabled[control]
    const setDependantsText = (tempExpertOpinion, checkedControls=secondaryChecked, disabledControls = disabled)=>{
        Object.keys(checkedControls['dependants']).filter(key=>!!checkedControls['dependants'][key] && !disabledControls[key]).sort().forEach(key=>{
            if(opinionConsructor['SECONDARY'][key]){
                tempExpertOpinion = setDependantControlText(opinionConsructor['SECONDARY'][key], tempExpertOpinion)
            }
            if(expertOpinionRequired()){
                tempExpertOpinion = addDependantControlText(opinionConsructor['ADDITION'][key], tempExpertOpinion)
            }
        })
        return tempExpertOpinion;
    }
    const setDependantControlText = (control, tempExpertOpinion=expertOpinion)=>{
        if (!!control && (!!control.status || !!control.todo || !!control.before)){
            const text = {
                status: !!control.status ? control.status : tempExpertOpinion.status,
                todo: !!control.todo ? control.todo : tempExpertOpinion.todo,
                before: !!control.before ? control.before : tempExpertOpinion.before
            }
            return {...tempExpertOpinion, ...text}
        }
    }
    const addDependantControlText = (control, tempExpertOpinion=expertOpinion)=>{
        let text={}
        if (!!control && (!!control.status || !!control.todo || !!control.before)){
             text = {
                status: (!!tempExpertOpinion.status?tempExpertOpinion.status:"") + (!!control.status ? control.status :""),
                todo: (!!tempExpertOpinion.todo?tempExpertOpinion.todo:"") + (!!control.todo ? control.todo : ""),
                before:  (!!tempExpertOpinion.before?tempExpertOpinion.before:"") + (!!control.before ? control.before :"")
            }
        }
        return {...tempExpertOpinion, ...text}
    }

    const isSecondaryChecked = (value)=>{
        return !!secondaryChecked[CaseControl[value].group][value]
    }
    const secondaryCheck = (value, checked)=>{
        const checkedControls = {...secondaryChecked}
        checkedControls[CaseControl[value].group][value]=checked
        setSecondaryChecked(checkedControls)
        const disabledControls = defineDisabledControls(checkedControls)
        setDisabled(disabledControls)
        let tempExpertOpinion={...expertOpinion}
        tempExpertOpinion = setDependantsText(tempExpertOpinion,checkedControls,disabledControls)
        completeExpertOpinion((prev)=>(tempExpertOpinion))
    }
    const saveRecommendationText = (group, value)=>{
        expertOpinion[group]=value
        setExpertOpinion({...expertOpinion})
    }

    const opinions = () => ({
        columns: [
            {
                label: "Эксперт",
                field: "name",
            },
            {
                label: "Дата",
                field: "opinionDate",
            },
            {
                label: "Диагноз / Сообщение",
                field: "Diagnose",
            },
            {
                label: "Отправлено",
                field: "Final",
            },
        ],
        rows: neoplasmData===null? [] :neoplasmData.caseImages[currentIndex].opinions.map(item =>(
            {
                name: item.expert.fio,
                opinionDate: new Date(item.date).toLocaleDateString('ru-RU',{hour:'2-digit', minute:'2-digit'}),
                Diagnose: <MDBCol><div> {item.diag.diagName} </div>
                    {item.report?
                    <div>
                        { item.report.startsWith('{')?
                            reportParagraphs(item.report).map((el,index)=><div key={index}>{el}</div>):
                            item.report
                        }
                    </div>
                    :null}</MDBCol>,
                Final: <MDBIcon key="status" icon={item.finalOpinion? "check": "minus"} size="1x" className="mr-2 grey-text" aria-hidden="true" />,
            })
        )
    });
    const reportParagraphs = (jsonReport) =>{
        const expertOp = JSON.parse(jsonReport)
        const paragraphs=[]
        paragraphs.push(expertOp.addressing.content)
        paragraphs.push(...signsParagraphs(expertOp.signs))
        paragraphs.push(...partParagraphs(expertOp.status))
        paragraphs.push(...partParagraphs(expertOp.todo))
        paragraphs.push(...partParagraphs(expertOp.before))
        paragraphs.push(expertOp.signature.intro + ' ' + expertOp.signature.name)
        return paragraphs
    }
    const signsParagraphs = (signs)=>{
        const paragraphs=[]
        if(!!signs ){
            const groups=[]
            paragraphs.push(signs.intro+' ')
            Object.keys(signs.content).forEach(key=> groups.push(key+': '+String(signs.content[key])))
            paragraphs.push(groups.join('. '))
        }
        return paragraphs
    }
    const partParagraphs=(part)=>{
        const paragraphs=[]
        if(!!part ){
            paragraphs.push(part.intro+' ')
            paragraphs.push(...part.paragraphs)
        }
        return paragraphs
    }
    const finalOpinion = (el => {
        const items = el.opinions.filter(opinion => opinion.finalOpinion===true)
        return items.length > 0? items[0]: null
    })
    const neuroOpinion = el=>{
        return el.opinions.find(opinion=>!opinion.expert.human)
    }

    const hasExpertOpinion = (data=neoplasmData)=>{
        return !!data && !!(data.caseImages[currentIndex].opinions.find(item =>item.expert.human && !!item.report))
    }

    const expertOpinionRequired =(data=neoplasmData)=>{
        return (!!data && data.caseImages[currentIndex].expertOpinionRequired && !hasExpertOpinion(data))
    }

    const images = neoplasmData===null? []: neoplasmData.caseImages.map(el => {
        const opinion = finalOpinion(el)
        return {
            src:`${baseUrl}/img/${el.filename}`,
            diagName: opinion===null? "Не определено": opinion.diag.diagName
        }
    })


    const getMessage = (message) => message.replace("<number>", neoplasmData.caseImages[currentIndex].id)

    const onChange = index => {
        setCurrentIndex(index)
    }
    const isOpinionEnabled = ()=> {
        return ((daysDiff(new Date(), neoplasmData.caseImages[currentIndex].imageDate)<=30 &&
            !hasExpertOpinion()) || expertOpinionRequired())
    }
    const daysDiff= (date1, date2)=>{
        //console.log("daysdiff =", Math.ceil((date1 - date2) / (1000 * 60 * 60 * 24)) )
        return Math.ceil((date1 - date2) / (1000 * 60 * 60 * 24));
    }
    const isolateCase = async () => {
        const response = await(props.prorodinkiService.isolateCase(neoplasmData.caseImages[currentIndex].id));
        setNewCaseTrigger(!newCaseTrigger)
    }
    const isEvidenceChecked = (group,value)=>{
        return !!evidenceChecked[group][value]
    }
    const checkEvidence = (group,value,checked)=>{
        evidenceChecked[group][value] = checked
        setEvidenceChecked({...evidenceChecked})
        saveRecommendationText("signs",getSignsObj())
    }
    const addEvidenceGroup = (signsObj, group)=>{
        if(!evidenceChecked[group]) return
        for (const el of Object.keys(Evidence[group])){
            if (evidenceChecked[group][el]){
                if(!signsObj[Evidence[group].label]){
                    signsObj[Evidence[group].label]=[]
                }
                signsObj[Evidence[group].label].push(Evidence[group][el].label)
            }
        }
    }
    const getSignsObj=()=>{
        const signsObj = {}
        Object.keys(Evidence).forEach(el=> {addEvidenceGroup(signsObj,el)})
        return JSON.stringify(signsObj)
    }

    //console.log("before render", neoplasmData, currentIndex)
    return (  neoplasmData===null || loadingStatus? null:
        <Case opinions={opinions}
              title = {`${neoplasmData.location.description}-${neoplasmData.orderNmb}. Обращение №${neoplasmData.caseImages[currentIndex].id}` +
              ` от ${new Date(neoplasmData.caseImages[currentIndex].imageDate).toLocaleDateString('ru-RU')}`}
              caseId = {neoplasmData.caseImages[neoplasmData.currentIndex].id}
              caseIndex = {currentIndex}
              error={error}
              selectedDiagnose={selectedDiagnose}
              attributes={attributes}
              patientProps={patientProps}
              diagnoseClick={diagnoseClick}
              images = {images}
              expertOpinion = {{...expertOpinion,isRequired:expertOpinionRequired, isEnabled:isOpinionEnabled, setRecommendation:setRecommendation, saveRecommendation:saveRecommendationText}}
              secondaryControls ={{isChecked:isSecondaryChecked, check:secondaryCheck, isDisabled:isDisabled}}
              evidence = {{isChecked:isEvidenceChecked, check:checkEvidence, checked:evidenceChecked}}
              tabs={{tabsValue, setTabsValue}}
              noMessage={{...noMessage,noMessageCheck}}
              onChange = {onChange}
              setDiagnose = {setDiagnose}
              isolate ={{onClick:isolateCase}}
              neighborLink = {getNeighborRef}
              exclusive ={{mode:caseParams.exclusiveMode, change:changeExclusiveMode}}
              nnProps ={{nnSuitable, changeNnSuitable}}
              getNnResults = {getNnResults}
        />)
}

export default withRouter(withProrodinkiService(CaseController))
