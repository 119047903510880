import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import Stack from "@mui/material/Stack";
import Box from '@mui/material/Box';
import {Diagnoses} from "../../components/diagnoses"
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {MDBBtn} from "mdbreact";



function SetDiagnoseFilterDialog(props) {
    const { onClose, checkedDiags, open, onCheck} = props;

    const handleClose = () => {
        onClose();
    };
    const AllByType = (checkedBoxes, type)=>{
        return(
            <Stack direction="column" spacing={-1}>

                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1"
                        id="panel1"
                    >
                        <FormControlLabel
                            sx={{ml:3}}
                            label={type==='MALIGNANT'? "Все Злокачественные":"Все Доброкачественные"}
                            control={
                                <Checkbox
                                    checked={isGroupAllChecked(checkedBoxes,type)}
                                    indeterminate={isGroupSomeChecked(checkedBoxes,type)}
                                    onClick={e => {
                                        e.stopPropagation()
                                        clickGroupBox(e,checkedBoxes,type)
                                    }}
                                />
                            }
                            key={type==='MALIGNANT'? 'ALLMALIGNANT': 'ALLBENIGN'}
                        />
                    </AccordionSummary>
                    <AccordionDetails>
                        <Stack direction="column">
                            {Diagnoses.filter(diag=> (type===diag.type))
                                .sort((a,b)=>(a.name>b.name)?1:(b.name>a.name)?-1:0).map(diag => (
                                    <FormControlLabel
                                        sx={{ml:6}}
                                        label={diag.name}
                                        control={
                                            <Checkbox
                                                checked={checkedBoxes[diag.id]}
                                                onClick={e => {
                                                    checkedBoxes[diag.id] =e.target.checked
                                                    onCheck()
                                                }}
                                            />}
                                        key={diag.id}
                                    />
                            ))}
                        </Stack>
                    </AccordionDetails>
                </Accordion>

            </Stack>
        )
    }
    const clickGroupBox = (e,checkedBoxes,type)=>{
        Diagnoses.filter(diag=> (type===diag.type))
            .forEach(diag=>{checkedBoxes[diag.id] = e.target.checked})
        onCheck()
    }
    const isGroupAllChecked = (checkedBoxes,type)=>{
        return Diagnoses.filter(diag=> (type===diag.type)).every(el=>checkedBoxes[el.id]===true)
    }
    const isGroupSomeChecked = (checkedBoxes,type)=>{
        return Diagnoses.filter(diag=> (type===diag.type)).some(el=>checkedBoxes[el.id]===true) &&
            !Diagnoses.filter(diag=> (type===diag.type)).every(el=>checkedBoxes[el.id]===true)
    }
    const isSomeChecked = (checkedGroup)=>{
        return Object.keys(checkedGroup).some(key=>checkedGroup[key]===true) && !Object.keys(checkedGroup).every(key=>checkedGroup[key]===true)
    }
    const isAllChecked = (checkedGroup)=>{
        return Object.keys(checkedGroup).every(key=>checkedGroup[key]===true)
    }


    const showDiagnoseCheckBoxes = (expertType)=>{
        return (
            <Stack sx={{ml:3}} direction="column" >
                <Typography variant="subtitle1">{expertType==='expert'?'Заключение эксперта:':'Заключение неросети:'}</Typography>
                <FormControlLabel
                    sx={{ml:1}}
                    label="Все"
                    control={
                        <Checkbox
                            checked={isAllChecked(checkedDiags[expertType])}
                            indeterminate={isSomeChecked(checkedDiags[expertType])}
                            onClick={e => {
                                Object.keys(checkedDiags[expertType]).forEach(key=> checkedDiags[expertType][key]=e.target.checked)
                                onCheck()
                            }}
                        />
                    }
                    key='All'
                />
                <FormControlLabel
                    sx={{ml:3}}
                    label="Заключения нет"
                    control={
                        <Checkbox
                            checked={checkedDiags[expertType]["UNDEFINED"]}
                            onClick={e => {
                                checkedDiags[expertType]["UNDEFINED"] =e.target.checked
                                onCheck()
                            }}
                        />
                    }
                    key='UNDEF'
                />
                <FormControlLabel
                    sx={{ml:3}}
                    label="Не распознано"
                    control={
                        <Checkbox
                            checked={checkedDiags[expertType]["UNRECOGNIZED"]}
                            onClick={e => {
                                checkedDiags[expertType]["UNRECOGNIZED"] =e.target.checked
                                onCheck()
                            }}
                        />
                    }
                    key='UNRECOGNIZED'
                />
                <FormControlLabel
                    sx={{ml:3}}
                    label="Вне области"
                    control={
                        <Checkbox
                            checked={checkedDiags[expertType]["OUT_OF_SCOPE"]}
                            onClick={e => {
                                checkedDiags[expertType]["OUT_OF_SCOPE"] =e.target.checked
                                onCheck()
                            }}
                        />
                    }
                    key='OUT_OF_SCOPE'
                />
                {AllByType(checkedDiags[expertType],"MALIGNANT")}
                {AllByType(checkedDiags[expertType],"BENIGN")}
            </Stack>
        )
    }
    return (
        <Dialog onClose={handleClose} open={open}  maxWidth={'lg'} scroll={'paper'}>
            <DialogTitle>Выберите интересующие диагнозы</DialogTitle>
            <Stack direction="row">
                {showDiagnoseCheckBoxes('expert')}
                {showDiagnoseCheckBoxes('neuro')}
            </Stack>
            <Box sx={{display:'flex', flexDirection:'row', justifyContent:'flex-end', my:1, mr:5}} >
                <Button size="small" onClick={()=>onClose()}>Применить</Button>
            </Box>
        </Dialog>
    );
}

SetDiagnoseFilterDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    onCheck: PropTypes.func.isRequired,
};

 function FilterDiagnoses({onClose, checkedDiags}){
    const [open, setOpen] = React.useState(false);
    const [checkedItems, setCheckedItems] = React.useState(checkedDiags);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleCheckItem = () => {
        setCheckedItems({...checkedItems});
    };

    const handleClose = (value) => {
        setOpen(false);
        onClose()
    };
    const getCheckedDiagCodes = (group)=>{
//        console.log("getCheckedDiagCodes",checkedItems)
        return Object.keys(checkedItems[group]).every(key=>checkedItems[group][key])? "Все":
            Object.keys(checkedItems[group]).filter(key=>checkedItems[group][key]).map(key=>{
            const d = Diagnoses.find(diag=> diag.id===key)
            return !!d? d.short: " Не опред."
        }).sort().join(", ")
    }

    return (
        <div>
            <Stack sx={{alignItems:"center"}} direction="row">
                {/*<MDBBtn outline={true} color={'blue'} size="sm" onClick={handleClickOpen} >*/}
                {/*    <strong>Диагнозы</strong>*/}
                {/*</MDBBtn>*/}
                <Button style={{textTransform: 'capitalize'}} color='primary' variant="outlined" onClick={handleClickOpen} size={'small'}>
                    Диагнозы
                </Button>
                <Box sx={{flexDirection:"column", ml:1}}>
                    <Typography style={{fontSize: 14, fontStyle: 'italic'}} variant='string' component="div">
                        Эксперты: {getCheckedDiagCodes("expert")}
                    </Typography>
                    <Typography style={{ fontSize: 14, fontStyle: 'italic'}} variant='string' component="div">
                        Нейросеть: {getCheckedDiagCodes("neuro")}
                    </Typography>
                </Box>
            </Stack>

            <SetDiagnoseFilterDialog
                checkedDiags={checkedItems}
                open={open}
                onClose={handleClose}
                onCheck = {handleCheckItem}
            />
        </div>
    );
}
export default (FilterDiagnoses);
