import withProrodinkiService from "../../components/hoc/with-prorodinki-service";
import {
    MDBBtn,
    MDBBtnGroup,
    MDBCard,
    MDBCardBody,
    MDBCol,
    MDBContainer,
    MDBDataTable, MDBIcon,
    MDBRow
} from "mdbreact";
import React, {useEffect, useState} from "react";
import {Diagnoses} from "../../components/diagnoses";


function  ArbitrageCase ({caseId,
                             caseIndex,
                             error,
                             selectedDiagnose,
                             attributes,
                             images,
                             setDiagnose,
                             opinions,
                            diagnoseClick,
                           })  {
    // console.log('aCase',selectedDiagnose)
    const [imageLoaded, setImageLoaded] = useState(false)
    useEffect(() => {
        window.addEventListener("keydown", handleUserKeyPress);
        return () => {
            window.removeEventListener("keydown", handleUserKeyPress);
        };
    }, []);
    const handleUserKeyPress =(event) => {
        const item = Diagnoses.find(el=> el.key===event.key)
        if(!!item){
            diagnoseClick(item)
        }
        // if(event.key==='Enter'){
        //     setDiagnose()
        // }
    };

    return (
        <MDBContainer className="py-5" >
            <MDBRow>
                <MDBCol md="6">
                    <MDBCard className="text-center" style={{backgroundColor: `${selectedDiagnose.background}`}}>
                        <MDBCardBody className='text-center'>
                            <div >
                                <img src={images[0].src} style={{width:'100%', visibility: imageLoaded?'visible':'hidden'}} className="img-fluid" alt="..."
                                     onLoad={()=>setImageLoaded(true)}
                                />
                            </div>
                            <MDBDataTable
                                autoWidth
                                responsive={true}
                                info={false}
                                hover
                                noBottomColumns
                                sortable={false}
                                data={attributes()}
                                paging={false}
                                noRecordsFoundLabel="Данных не обнаружено"
                                searching={false}
                            />
                        </MDBCardBody>
                    </MDBCard>
                    {/*</SectionContainer>*/}
                </MDBCol>
                <MDBCol md="3">
                        <MDBContainer className="text-left">
                            <h5> Имеющиеся заключения: </h5>
                            <MDBDataTable
                                autoWidth
                                responsive={true}
                                info={false}
                                hover
                                noBottomColumns
                                sortable={false}
                                data={opinions()}
                                paging={false}
                                noRecordsFoundLabel="Данных не обнаружено"
                                searching={false}
                                maxHeight = {16}
                                small
                            />
                        </MDBContainer>
                </MDBCol>
                <MDBCol md={3}>
                    <MDBContainer className="text-left">
                        <MDBRow>
                            <MDBCol md="6">
                                <h5> Ваше заключение: </h5>
                                <MDBBtnGroup vertical size="sm">
                                    {Diagnoses.map(item =>
                                        <MDBBtn key={item.id} outline={item.id !== selectedDiagnose.id}
                                                                   className="mt-3" color={item.color} onClick={() => {
                                        diagnoseClick(item) }} >
                                        <strong>{item.name+' ('+item.key+')'}</strong>
                                    </MDBBtn>)
                                    }
                                </MDBBtnGroup>
                            </MDBCol>
                        </MDBRow>
                    </MDBContainer>
                    <MDBContainer className="mt-2 text-left">
                        <MDBBtnGroup className={"align-items-center"}>
                            <MDBBtn color="primary" size={"sm"} disabled={selectedDiagnose.id === null}
                                    onClick={() => setDiagnose(caseId,selectedDiagnose.id)}>
                                {"Подтвердить"}
                                <MDBIcon icon="check" />
                            </MDBBtn>
                        </MDBBtnGroup>
                    </MDBContainer>

                </MDBCol>
            </MDBRow>
        </MDBContainer>
    )
}
export default withProrodinkiService(ArbitrageCase)
