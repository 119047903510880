import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {MDBContainer} from "mdbreact";
import React from "react";
import Typography from "@mui/material/Typography";
const BaseTable = (props) => {
    const {title, data, size} = props
    const {rows, columns} = data
    return (
        (!!rows && !!columns)?
        <TableContainer component={Paper}>
            <Typography  variant='h6' component="div"> {title}</Typography>
            <Table sx={{ minWidth: 650 }} size={!!size?size:"medium"} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        {columns.map((column, index)=>(<TableCell key={index} align="left">{column.label}</TableCell>))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row,index) => (
                        <TableRow
                            key={index}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            {columns.map((column,index)=>(<TableCell style={{whiteSpace: "pre-line"}} key={index} align="left">{row[column.field]}</TableCell>))}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>:null
    )
}
export default BaseTable
