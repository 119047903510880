import * as React from 'react';
import {baseUrl} from "../../config";
import Box from "@mui/material/Box";
import {MDBDataTable, MDBIcon} from "mdbreact";

export default function CaseTable({order,imageData,clickEvent,onSort}) {
    const statusIcon = new Map([
        ["PENDING", "hourglass"],
        ["SECOND_OPINION", "question-circle"],
        ["DIAGNOSED", "envelope"],
        ["READ_BY_PATIENT", "envelope-open"],
    ]);

    const iconName = (item) =>{
        return item.orderState==="OPEN"? "dollar-sign": statusIcon.get(item.orderState==="OPEN"? "PAID": item.caseStatus)
    };
    const data = () => ({
        columns: [
            {
                label: "Номер",
                field: "id",
                //width: 100,
                sort: order[0]==='id'? order[1]: null
            },
            {
                label: "Время поступления",
                field: "caseDate",
                // width: 200,
                sort: order[0]==='caseDate'? order[1]: null
            },
            {
                label: "Эксперт",
                field: "expertFio",
                // width: 300,
                sort: order[0]==='expertFio'? order[1]: null
            },
            {
                label: "Заключение эксперта",
                field: "expertOpinion",
                // width: 370,
                sort: order[0]==='expertOpinion'? order[1]: null
            },
            {
                label: "Заключение нейросети",
                field: "neuralOpinion",
                // width: 370,
                sort: order[0]==='neuralOpinion'? order[1]: null
            },
            {
                label: "Итоговое заключение",
                field: "finalOpinion",
                // width: 370,
                sort: order[0]==='finalOpinion'? order[1]: null
            },
            {
                label: "Статус",
                field: "caseStatus",
                // width: 300,
                sort: order[0]==='caseStatus'? order[1]: null
            }
        ],

        rows: imageData.map(item =>(
            {
                id: item.id,
                caseDate: new Date(item.caseDate).toLocaleDateString('ru-RU',{hour:'2-digit', minute:'2-digit'}),
                expertFio: item.expertFio,
                expertOpinion: item.humanDiagData.diagName,
                neuralOpinion: item.neuralDiagnosis.diagName,
                finalOpinion: item.finalDiagnosis.diagName,
                caseStatus: <MDBIcon key="status" icon={iconName(item)} size="2x" className="mr-2 grey-text" aria-hidden="true" />,
                clickEvent: () => clickEvent(item.id),
            })
        )
    });

    return (
        <Box sx={{ mx:5}}>
            <MDBDataTable
                responsive={true}
                striped
                bordered
                hover
                searching={false}
                paging={true}
                infoLabel={["от", "до", "из", "строк"]}
                entries={50}
                entriesLabel="Строк на странице"
                paginationLabel={["Назад","Вперёд"]}
                entriesOptions={[10,25,50,100]}
                pagesAmount={4}
                info={true}
                data={data()}
                onSort={onSort}
                noRecordsFoundLabel={"Данные не обнаружены"}
                noBottomColumns
            />
        </Box>
    );
}

