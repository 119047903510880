import React, {Component, Fragment, useState} from "react";
import withProrodinkiService from "../../components/hoc/with-prorodinki-service";
import {baseUrl} from "../../config"
import {
    MDBBtn,
    MDBCol,
    MDBRow,
    MDBContainer,
    MDBCard,
    MDBCardBody,
    MDBIcon,
    MDBCardImage,
    MDBCardTitle,
    MDBCardText,
    MDBDataTable,
    MDBBtnGroup,
    MDBCardHeader
} from "mdbreact"
import {Link } from 'react-router-dom';
import ErrorCheck from "../../components/error-check";
import {Layer, Rect, Stage} from "react-konva";
import {Carousel} from "react-responsive-carousel";
import MouseTooltip from "react-sticky-mouse-tooltip";
import useResizeObserver from "@react-hook/resize-observer";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const Consultation = ({   title,
                          opinions,
                          caseId,
                          caseIndex,
                          error,
                          attributes,
                          images,
                          onChange,
                          getNnResults,
                          message,
                          onCommit,
                }) =>{
    const useSize = (target) => {
        const [size, setSize] = React.useState()

        React.useLayoutEffect(() => {
            // console.log('target.current',target.current)
            if(!!target.current)  setSize(target.current.getBoundingClientRect())
        }, [target])

        // Where the magic happens
        useResizeObserver(target, (entry) => setSize(entry.contentRect))
        // console.log('size',size)
        return !!size?size:{width:0,height:0,x:0,y:0}
    }
    const [showNnResults, setShowNnResults] = useState(-1)
    const [isOpen, setIsOpen] = useState(false)
    const target = React.useRef(null)
    const size = useSize(target)
    const handleShowDialog = () => {
        setIsOpen(!isOpen)
        //console.log("clicked");
    };
    const confirmCancel = () => {
        return window.confirm("Подтвердите отмену заключения")
    }
    return (
        <MDBContainer className="py-5" >
            <ErrorCheck error={error.isError} message={error.errorMessage}>
                <MDBRow>
                    <MDBCol md="6">
                        <MDBCard className="text-center" style={{backgroundColor: "#FFFFFF"}}>
                            <MDBCardTitle className="pt-3">
                                {title}
                            </MDBCardTitle>
                            <MDBCardBody className='text-center'>
                                {!!(getNnResults().caseObjects) && getNnResults().caseObjects.length>0?
                                    <div ref={target} >
                                        {!isOpen ?
                                            <div onClick={() => handleShowDialog()}
                                                 style={{position: 'absolute', zIndex: 100}}>
                                                <Stage width={size.width} height={size.width}>
                                                    <Layer>
                                                        {
                                                            getNnResults().caseObjects.map((rect, index) =>
                                                                <Rect
                                                                    key={index}
                                                                    x={rect.leftX * size.width}
                                                                    y={rect.topY * size.width}
                                                                    width={(rect.rightX - rect.leftX) * size.width}
                                                                    height={(rect.bottomY - rect.topY) * size.width}
                                                                    stroke={"green"}
                                                                    onMouseOver={() => setShowNnResults(index)}
                                                                    onMouseOut={() => setShowNnResults(-1)}
                                                                />)
                                                        }
                                                    </Layer>
                                                </Stage>

                                            </div>:
                                            isOpen && (
                                                <dialog
                                                    className="dialog"
                                                    style={{position: "absolute", zIndex: 101}}
                                                    open
                                                    onClick={() => handleShowDialog()}
                                                >
                                                    <img
                                                        className="image"
                                                        src={images[caseIndex].originalName}
                                                        width={size.width}
                                                        onClick={() => handleShowDialog()}
                                                        alt="no image"
                                                    />
                                                </dialog>
                                            )
                                        }
                                    </div>:null}
                                <div className="carousel-wrapper px-1">
                                    <Carousel infiniteLoop
                                              useKeyboardArrows
                                              onChange = {index => onChange(index) }
                                              selectedItem = {caseIndex}
                                    >
                                        {images.map( el =>
                                            { //console.log('el.src', el.src)
                                                return(
                                                <div key={el}>
                                                    <img src = {el.src} />
                                                    {/*<p className="legend">{el.diagName}</p>*/}
                                                </div>)
                                            }
                                        )}
                                    </Carousel>
                                </div>
                                {showNnResults>=0?
                                    <div className="amber-text">
                                        <MouseTooltip
                                            visible={showNnResults>=0}
                                            offsetX={15}
                                            offsetY={10}
                                        >
                                            <span>{getNnResults().caseObjects[showNnResults].nnDiagnosisList.map(el=>el.diagName+':'+el.confidence.toFixed(2)).join(',')}</span>
                                        </MouseTooltip>
                                    </div>:null}
                                <MDBDataTable
                                    autoWidth
                                    responsive={true}
                                    info={false}
                                    hover
                                    noBottomColumns
                                    sortable={false}
                                    data={attributes()}
                                    paging={false}
                                    noRecordsFoundLabel="Данных не обнаружено"
                                    searching={false}
                                />
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                    <MDBCol md="6">
                        <MDBContainer className="mt-2 text-left">
                            <h5> Имеющиеся заключения: </h5>
                            <MDBDataTable
                                autoWidth
                                responsive={true}
                                info={false}
                                hover
                                noBottomColumns
                                sortable={false}
                                data={opinions()}
                                paging={false}
                                noRecordsFoundLabel="Данных не обнаружено"
                                searching={false}
                            />
                        </MDBContainer>
                        <MDBContainer className="text-left">
                            <MDBRow>
                                <MDBCol md="6">
                                    <h5> Ваше заключение: </h5>
                                    <FormControl variant="standard" sx={{ m: 1, minWidth: 200 }}>
                                        <InputLabel id="message_select_label">Выберите шаблон</InputLabel>
                                        <Select
                                            labelId="message_select_label"
                                            id="message_select"
                                            value={0}
                                            //onChange={message.messageSelectValueChange}
                                        >
                                            <MenuItem key={0} value={0}>
                                                <em>Шаблоны отсутствуют</em>
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                    <textarea value={message.getMessageText()}
                                              rows="20" cols="80"
                                              disabled={false}
                                              onChange={(event) => message.messageTextPersist(event)}
                                    />
                                </MDBCol>
                            </MDBRow>
                        </MDBContainer>
                        <MDBContainer className="mt-2 text-left">
                            <MDBBtnGroup className={"align-items-center ml-2"}>
                                <MDBBtn color="primary" size="md" disabled={false}
                                        onClick={()=>onCommit(1)}>
                                    {"Сохранить"} <MDBIcon icon="check" />
                                </MDBBtn>
                                <MDBBtn className="ml-2" color="primary" size="md" disabled={false}
                                        onClick={()=> {
                                            if(confirmCancel()){
                                                onCommit(0)
                                            }
                                        }}>
                                    {"Отменить"} <MDBIcon icon="xmark" />
                                </MDBBtn>
                            </MDBBtnGroup>
                        </MDBContainer>
                    </MDBCol>
                </MDBRow>
            </ErrorCheck>
        </MDBContainer>
    );

};
export default Consultation
