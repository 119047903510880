import {MDBBtn, MDBBtnGroup, MDBCard, MDBCardBody, MDBCol, MDBContainer, MDBDataTable, MDBIcon, MDBRow} from "mdbreact";
import React from "react";
import {baseUrl} from "../../config";
import {Diagnoses} from "../../components/diagnoses";

const ExamResults = ({examData, newExamClick}) =>{

  const getResultIcon = (examDiagnose, realDiagnose) => {
    return (
      examDiagnose===realDiagnose?
        <MDBIcon icon="thumbs-up" size="2x" className="green-text" />:
        <MDBIcon icon="thumbs-down" size="2x" className="red-text" />
    )
  }

  const opinions = () => ({
    columns: [
      {
        label: "№ теста",
        field: "number",
      },
      {
        label: "Фото",
        field: "image",
      },
      {
        label: "Ваше заключение",
        field: "examOpinion",
      },
      {
        label: "Подтвержденный диагноз",
        field: "diagnose",
      },
      {
        label: "Результат",
        field: "examResult",
      },
    ],
    rows: examData===null? []:examData.map((item,index) =>{
      const diagName = Diagnoses.find((element) =>(element.id === item.diagnose)).name;
      console.log("Exam item", item);
      console.log("Diagname", diagName);
      return (
          {
            number: index+1,
            image: <img src={`${baseUrl}/img/${item.imageName}`}
                        className="img-fluid "
                        alt = {`photo ${index}`}
            />,
            examOpinion: item.examDiagnosis.name,
            diagnose: diagName,
            examResult:  getResultIcon(item.examDiagnosis.name, diagName)
          })
      }
    )
  });

  return (
    <MDBContainer fluid className="py-5" >
        <MDBRow>
          <MDBCol md="9">
            <MDBCard>
              <MDBCardBody>
                <MDBContainer className="text-left">
                  <h5> {`Результат: правильных ответов - ${(examData===null? []:examData.filter(item =>(item.examDiagnosis.name ===Diagnoses.find((element) =>(element.id === item.diagnose)).name))).length} из ${(examData===null? []:examData).length}`} </h5>
                  <MDBDataTable
                    autoWidth
                    responsive={true}
                    info={false}
                    hover
                    noBottomColumns
                    sortable={false}
                    data={opinions()}
                    paging={false}
                    noRecordsFoundLabel="Данных не обнаружено"
                    searching={false}
                    maxHeight="100px"
                  />
                  <MDBContainer className="text-center">
                    <MDBBtn color="primary"  onClick={newExamClick}>Еще разок...
                       <MDBIcon icon="redo" className="ml-1"/>
                    </MDBBtn>
                  </MDBContainer>
                </MDBContainer>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
    </MDBContainer>
  )
}
export default ExamResults