import React from 'react';
import './loader.css';

const Loader = (isLoading) => {
    return (
        <div className="ring-container">
            <div className="lds-dual-ring"></div>
            <div>Загрузка данных...</div>
        </div>
    )
}
export default Loader