import * as React from 'react';
import Box from '@mui/material/Box';

import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import TablePagination from "@mui/material/TablePagination";

export default function UserTablePagination(props) {
    const { count, page, rowsPerPage, onPageChange, onRowsPerPageChange} = props;
    //console.log("UserTablePagination page",page)

    return (
        <TablePagination
            sx={{mr:10,width:'100%'}}
            component="div"
            showFirstButton
            showLastButton
            count={count}
            page={page}
            onPageChange={onPageChange}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={onRowsPerPageChange}
            labelRowsPerPage={"Строк на странице"}
        />
        // <Box sx={{ ml:5, mr:10, maxWidth:2500, display:'flex', flexDirection:'row',  justifyContent:"flex-end"}}>
        //     <IconButton
        //         onClick={handleFirstPageButtonClick}
        //         disabled={page === 0}
        //         aria-label="first page"
        //     >
        //         <FirstPageIcon />
        //     </IconButton>
        //     <IconButton
        //         onClick={handleBackButtonClick}
        //         disabled={page === 0}
        //         aria-label="previous page"
        //     >
        //         <KeyboardArrowLeft />
        //     </IconButton>
        //     <IconButton
        //         onClick={handleNextButtonClick}
        //         disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        //         aria-label="next page"
        //     >
        //         <KeyboardArrowRight />
        //     </IconButton>
        //     <IconButton
        //         onClick={handleLastPageButtonClick}
        //         disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        //         aria-label="last page"
        //     >
        //        <LastPageIcon />
        //     </IconButton>
        // </Box>
    );
}
