import {MDBCol, MDBContainer, MDBDataTable} from "mdbreact";
import React from "react";
import BaseTable from "./BaseTable";

const ConclusionTable = (props) => {
    const {opinions,attributes,patientProps } = props

    return (
        <MDBContainer className="text-left">
            <BaseTable title={'Имеющиеся заключения:'} data={opinions()}/>
            <BaseTable title={'Сведения о пациенте и новообразовании:'} data={attributes()}/>
            <BaseTable title={'Профиль:'} data={patientProps} size={'small'}/>
        </MDBContainer>
    )
}
export default ConclusionTable
