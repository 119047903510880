import axios from "axios";
import {baseUrl} from "../config"
export default class ProrodinkiService {

    caseList =[]
    arbitrageList=[]
    orders=0

  processError(response,error)  {
      response.hasError =true
      if (error.response) {
          /*
           * The request was made and the server responded with a
           * status code that falls out of the range of 2xx
           */
          response.error.code = error.response.status
          response.error.message = error.response.data.message
      } else if (error.request) {
          /*
           * The request was made but no response was received, `error.request`
           * is an instance of XMLHttpRequest in the browser and an instance
           * of http.ClientRequest in Node.js
           */
          response.error.code = -1
          response.error.message = "Не удалось получить ответ от сервера. Возможно сервер не активен или отсутствует сетевое соединение"

      } else {
          // Something happened in setting up the request and triggered an Error
          response.error.code = -2
          response.error.message = error.message
      }
  }

  initResponse = () => ({
      data:null,
      hasError:false,
      error:{
          code:null,
          message:null
      }})

  async getCasesList(from) {
      let response = this.initResponse();
      try {

          const res = await axios.get(`${baseUrl}/cases`,
              {
                  params: {from: from.getTime()},
                  headers: {'Authorization':`Bearer ${this.getItem('token')}`}
              });
          response.data = res.data;
      }
      catch (error){
          this.processError(response,error)
      }
      this.caseList = response.data
      return response;
  }
    async getCasesList2(from, to) {
        let response = this.initResponse();
        try {

            let res = await axios.get(`${baseUrl}/cases`,
                {
                    params: {from: from.getTime(), to:to.getTime()},
                    headers: {'Authorization':`Bearer ${this.getItem('token')}`}
                });
            response.data = res.data;
            res = await axios.get(`${baseUrl}/cases/orders/count`,
                {
                    headers: {'Authorization':`Bearer ${this.getItem('token')}`}
                });
            this.orders=res.data
        }
        catch (error){
            this.processError(response,error)
        }
        this.caseList = response.data
        return response;
    }

    async getWaitingOrders() {
        let response = this.initResponse();
        try {
            const res = await axios.get(`${baseUrl}/cases/orders`,
                {
                    headers: {'Authorization':`Bearer ${this.getItem('token')}`}
                });
            response.data = res.data;
        }
        catch (error){
            this.processError(response,error)
        }
        this.caseList = response.data
        this.orders=this.caseList.length
        //console.log("getWaitingOrders ",this.caseList)
        return response;
    }

    async getCase(caseId) {
        let response = this.initResponse();
        try {
            const res = await axios.get(`${baseUrl}/cases/${caseId}`,
                {
                    headers: {'Authorization':`Bearer ${this.getItem('token')}`}
                });
            response.data = res.data;
        }
        catch (error){
            this.processError(response,error)
        }
        // console.log("getCase response",response)
        return response;
    }
    async getVerificationCase() {
        let response = this.initResponse();
        try {
            const res = await axios.get(`${baseUrl}/verification/cases`,
                {
                    headers: {'Authorization':`Bearer ${this.getItem('token')}`}
                });
            response.data = res.data;
        }
        catch (error){
            this.processError(response,error)
        }
        return response;
    }
    async getConsultationCase(caseId) {
        let response = this.initResponse();
        try {
            const res = await axios.get(`${baseUrl}/consultation/request`,
                {
                    headers: {'Authorization':`Bearer ${this.getItem('token')}`}
                });
            response.data = res.data;
        }
        catch (error){
            this.processError(response,error)
        }
        return response;
    }
    async getConsultationCaseByRequest(requestNumber) {
        let response = this.initResponse();
        try {
            const res = await axios.get(`${baseUrl}/consultation/request/${requestNumber}`,
                {
                    headers: {'Authorization':`Bearer ${this.getItem('token')}`}
                });
            response.data = res.data;
        }
        catch (error){
            this.processError(response,error)
        }
        return response;
    }
    async getObjectToMark() {
        let response = this.initResponse();
        try {
            const res = await axios.get(`${baseUrl}/markups`,
                {
                    headers: {'Authorization':`Bearer ${this.getItem('token')}`}
                });
            response.data = res.data;
        }
        catch (error){
            this.processError(response,error)
        }
        return response;
    }
    async postMarkups(imageId, fileName, mode, width, height, rectangles, result){
        let response = this.initResponse();
        try{
            const res = await axios.post(`${baseUrl}/markups`,
                {imageId,fileName,mode, width,height, rectangles,result},
                {headers: {'Authorization':`Bearer ${this.getItem('token')}`}
                })
            response.data = res.data;
        }
        catch (error) {
            this.processError(response,error)
        }
        return response;
    }

    async putCaseMove(caseId, caseIds, exclusiveMode) {
        let response = this.initResponse();

        try {
            const res = await axios.put(`${baseUrl}/cases/${caseId}/move`,
                { caseIds, exclusiveMode},
                {headers: {'Authorization':`Bearer ${this.getItem('token')}`}}
                );
            response.data = res.data;
        }
        catch (error){
            this.processError(response,error)
        }
        return response;
    }

    async putNnSuitable(caseId, nnSuitable){
        const res = await axios.put(`${baseUrl}/cases/${caseId}`,
            null,
            {headers: {'Authorization':`Bearer ${this.getItem('token')}`},
                params:{nnSuitable}
            }
        );
    }

  async getExam() {
    let response = this.initResponse();
    try {
      const res = await axios.get(`${baseUrl}/verification/cases/exam`,
        {
          headers: {'Authorization':`Bearer ${this.getItem('token')}`}
        });
      response.data = res.data;
    }
    catch (error){
      this.processError(response,error)
    }
    return response;
  }


  async postLogin(phone,password){
      let response = this.initResponse();
      try{
          const res = await axios.post(`${baseUrl}/login`, {phone,password})
          this.storeUser(res.data)
          response.data = res.data;
      }
      catch (error) {
          this.processError(response,error)
      }
      return response;
  }

  async putDiagnose(case_id, diagnose, expertOpinion){
    let response = this.initResponse();
    try{
        const diagData = {diagnosis:diagnose, case_id, expertOpinion}
        // console.log("putDiagnose", diagData)
        const res = await axios.post(`${baseUrl}/neoplasms/diagnose/v2`, diagData, {
        headers: {'Authorization':`Bearer ${this.getItem('token')}`}
      })
      response.data = res.data;
    }
    catch (error) {
      this.processError(response,error)
    }
    return response;
  }
    async putDiagnoseSeries(caseIds, diagnose){
        let response = this.initResponse();
        try{
            const diagData = {diagnosis:diagnose, caseIds:caseIds}
            // console.log("putDiagnose", diagData)
            const res = await axios.post(`${baseUrl}/neoplasms/diagnose/series`, diagData, {
                headers: {'Authorization':`Bearer ${this.getItem('token')}`}
            })
            response.data = res.data;
        }
        catch (error) {
            this.processError(response,error)
        }
        return response;
    }
    async putAgreeNetworkDiagnoseSeries(caseIds){
        let response = this.initResponse();
        try{
            const diagData = {diagnosis:null, caseIds:caseIds}
            // console.log("putDiagnose", diagData)
            const res = await axios.post(`${baseUrl}/neoplasms/ai_diagnose/series`, diagData, {
                headers: {'Authorization':`Bearer ${this.getItem('token')}`}
            })
            response.data = res.data;
        }
        catch (error) {
            this.processError(response,error)
        }
        return response;
    }
    async putAgreeExpertDiagnoseSeries(caseIds){
        let response = this.initResponse();
        try{
            const diagData = {diagnosis:null, caseIds:caseIds}
            // console.log("putDiagnose", diagData)
            const res = await axios.post(`${baseUrl}/neoplasms/expert_diagnose/series`, diagData, {
                headers: {'Authorization':`Bearer ${this.getItem('token')}`}
            })
            response.data = res.data;
        }
        catch (error) {
            this.processError(response,error)
        }
        return response;
    }

    async isolateCase(caseId){
        let response = this.initResponse();
        try{
            // console.log("isolateCase", caseId)
            const res = await axios.post(`${baseUrl}/neoplasms/isolate_case/${caseId}`, null, {
                headers: {'Authorization':`Bearer ${this.getItem('token')}`}
            })
            response.data = res.data;
        }
        catch (error) {
            this.processError(response,error)
        }
        return response;
    }
    storeUser(user){
      localStorage.setItem('fio', user.fio);
      localStorage.setItem('roles', user.roles);
      localStorage.setItem('token', user.token);
    }

  getUser(){
      return {
          fio:localStorage.getItem('fio'),
          roles:localStorage.getItem('roles'),
          token:localStorage.getItem('token'),
      }
  }
  removeUser(){
      localStorage.removeItem('fio')
      localStorage.removeItem('roles')
      localStorage.removeItem('token')
  }
  getItem(key){
      return localStorage.getItem(key)
  }

    async putVerificationDiagnose(caseId, diagnose, boundaries){
        let response = this.initResponse();
        try{
            const res = await axios.put(`${baseUrl}/verification/cases/${caseId}/${diagnose}`, {boundaries}, {
                headers: {'Authorization':`Bearer ${this.getItem('token')}`}
            })
            response.data = res.data;
        }
        catch (error) {
            this.processError(response,error)
        }
        return response;
    }
    async putArbitrageDiagnose(caseId, diagnose ){
        let response = this.initResponse();
        try{
             const res = await axios.put(`${baseUrl}/arbitrage/${caseId}/${diagnose}`, {}, {
                headers: {'Authorization':`Bearer ${this.getItem('token')}`}
            })
            response.data = res.data;
        }
        catch (error) {
            this.processError(response,error)
        }
        return response;
    }

    async getArbitrageImages(diagnoseId, offset, limit){
        let response = this.initResponse();
        try{
            const res = await axios.get(`${baseUrl}/arbitrage`,  {
                params: {diagnoseId, offset, limit},
                headers: {'Authorization':`Bearer ${this.getItem('token')}`}
            })
            response.data = res.data;
        }
        catch (error) {
            this.processError(response,error)
        }
        return response;
    }
    async postConclusion(consConclusion){
        let response = this.initResponse();
        try{
            //console.log("postConclusion consConclusion",consConclusion)
            const res = await axios.post(`${baseUrl}/consultation/conclusion`,
                {...consConclusion},
                {headers: {'Authorization':`Bearer ${this.getItem('token')}`}
                })
            //console.log("postConclusion response",res)
            response.data = res.data;
        }
        catch (error) {
            // console.log("postConclusion error",error)
            this.processError(response,error)
        }
        return response;
    }
}
