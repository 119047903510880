const Evidence = {
    symmetry_group:{label:'Симметрия новообразования',
        symmetric: {label:"симметричное"},
        asymmetrical: {label:"несимметричное"},
    },
    surface_group:{label:'Поверхность',
        smooth: {label:"гладкая"},
        flaky: {label:"с шелушением"},
        vessels: {label:"с разветвленными сосудами "},
        crusts: {label:"с серозно-геморрагическими корочками"},
        ulceration: {label:"с изъязвлением"},
        bleeding: {label:"изъязвление с кровоточивостью"},
        sinking: {label:"с западением в центре"},
    },
    form_group:{label:'Форма',
        regular: {label:"правильная"},
        irregular: {label:"неправильная"},
    },
    contour_group:{label:'Контур',
        round: {label:"круглый"},
        oval: {label:"овальный"},
    },
    volume_group:{label:'Объем',
        over: {label:"возвышается над поверхностью"},
        flat: {label:"новообразование плоское"},
    },
   border_group:{label:'Границы',
        clear: {label:"четкие"},
        fuzzy: {label:"размытые"},
    },
    color_group:{label:'Цвет',
        black: {label:"черный"},
        darkbrown: {label:"темно-коричневый"},
        lightbrown: {label:"светло-коричневый"},
        gray: {label:"серый"},
        bluegray: {label:"серо-голубой"},
        red: {label:"красный"},
        violet: {label:"фиолетовый"},
        pink: {label:"розовый"},
        multicolor: {label:"многоцветный"},
    }
}
export default Evidence
