import Card from "@mui/material/Card";
import {baseUrl} from "../../config";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import EmailIcon from "@mui/icons-material/Email";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import * as React from "react";
import {useEffect, useState} from "react";

export function ImageItem ({item, imgWidth, onImageClick, onCheckChange}){
    const onChangeItemState =(e)=>{
        item.checkState = e.target.checked
        onCheckChange()
    }
    const isChecked =()=>{
        // console.log('isChecked item', item, item.checkState)
        return !!item.checkState
    }
    //console.log('ImageItem item', item)
    return (
            <div style={{position: 'relative'}} >
                <img
                    src={`${baseUrl}/img/${item.imagePath}`}
                    alt={item.imagePath}
                    height={imgWidth}
                    width={imgWidth}
                    onClick={(event)=>onImageClick(item.id)}
                    loading="lazy"
                />
                {   item.orderState === "OPEN"? null:
                    <div style={{position: 'absolute', top: 0, left: 4}}>
                        <FormControlLabel
                            control={<Checkbox sx={{color: "#ffffff",'&.Mui-checked': {color: "#ffffff",},}}/>}
                            checked={isChecked()}
                            value={item.id}
                            onChange={onChangeItemState}
                            label={""}
                        />
                    </div>
                }
                <div style={{position: 'absolute',top: 0, right: 4}} >
                    <IconButton
                        sx={{ color: (item.humanDiagData.severity==='EMERGENCY' ||  item.neuralDiagnosis.severity==='EMERGENCY')?
                                '#b71c1c': 'rgba(255, 255, 255, 0.8)'
                        }}
                        onClick={(event)=>onImageClick(item.id)}
                    >
                        {
                            item.orderState === "OPEN" ? <AttachMoneyIcon/>:
                                item.caseStatus === "PENDING" ? <HourglassBottomIcon/> :
                                    item.caseStatus === "SECOND_OPINION" ? <QuestionMarkIcon/> :
                                        item.caseStatus === "DIAGNOSED" ? <EmailIcon/> :
                                            item.caseStatus === "READ_BY_PATIENT" ? <MarkEmailReadIcon/> : null
                        }
                    </IconButton>
                </div>
            </div>
    )
}
