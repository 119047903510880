import React, { Component } from "react";
import {
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBCard,
    MDBCardBody,
    MDBModalFooter,
    MDBIcon,
    MDBCardHeader,
    MDBBtn,
    MDBInput
} from "mdbreact";
import logo from "../../logo_contour_white.png";
import withProrodinkiService from "../../components/hoc/with-prorodinki-service";

class LoginCard extends Component{

    state = {
        phone: '+7',
        password: '',
        errorMessage: '',
        error: false,
    };

    handleUserInput = (e) => {
        const name = e.target.id;
        const value = e.target.value;
        this.setState({[name]: value});
    }

    sendForm = async () =>{
        let phone =this.state.phone.length>1 && this.state.phone[0]==='+'? this.state.phone.slice(1):this.state.phone
        const response = await(this.props.prorodinkiService.postLogin(phone, this.state.password));
        if (response.hasError) {
            this.setState({
                error:true,
                errorMessage:response.error.message
            })
            // console.log('Error returned:', response.error)
        } else {
            this.setState({
                error:false,
                errorMessage:''
            })
            this.props.setUser();
        }
    }

    render() {
        return (
            <MDBContainer>
                <MDBRow className="d-flex align-items-center">
                    <MDBCol md="9" className='align-self-center'>
                        <MDBCard className='z-depth-1-half'>
                            <MDBCardBody>
                                <MDBCardHeader className="form-header unique-color dark rounded">
                                    <h3 className="my-3 white-text">
                                        <img src={logo} alt="logo" className='py-0 my-0' style={{width: "4rem"}}/>
                                        <span className='pl-2'> Добро пожаловать: </span>
                                    </h3>
                                </MDBCardHeader>
                                <form>
                                     {this.state.error && <div className="red-text">{this.state.errorMessage}</div>}
                                     <div className="grey-text">
                                        <MDBInput
                                            id="phone"
                                            label="Ваш номер телефона"
                                            icon="phone"
                                            group
                                            type="text"
                                            autoComplete="username"
                                            className="form-control"
                                            value={this.state.phone}
                                            onInput={this.handleUserInput}
                                        >
                                            <small id="phoneHelp" className="form-text text-muted">
                                                в формате +79991234567 без пробелов и разделителей.
                                            </small>
                                        </MDBInput>
                                        <MDBInput
                                            id="password"
                                            label="Пароль"
                                            icon="key"
                                            group
                                            type="password"
                                            autoComplete="current-password"
                                            validate
                                            value={this.state.password}
                                            onInput={this.handleUserInput}
                                        />
                                     </div>

                                    <div className="text-center mt-4">
                                        <MDBBtn
                                            color="light-blue"
                                            className="mb-3"
                                            onClick={this.sendForm}
                                        >
                                            <MDBIcon icon="unlock-alt"/>
                                            <strong className='pl-2'> Вход </strong>
                                        </MDBBtn>
                                    </div>
                                </form>
                                <MDBModalFooter>
                                    <div className="font-weight-light  text-center">
                                        <p className='footer-copyright mb-0 py-3'>
                                            &copy; {new Date().getFullYear()} Copyright:
                                            <a href='https://www.prorodinki.ru'> Prorodinki.ru </a>
                                        </p>
                                    </div>
                                </MDBModalFooter>
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        );
    }
}
export default withProrodinkiService(LoginCard)
