import React from 'react';
import { ProrodinkiServiceConsumer } from '../prorodinki-service-context';

const withProrodinkiService = (Wrapped) => {

  return (props) => {
    return (
      <ProrodinkiServiceConsumer>
        {
          (prorodinkiService) => {
            return (<Wrapped {...props}
                             prorodinkiService={prorodinkiService}/>);
          }
        }
      </ProrodinkiServiceConsumer>
    );
  }
};

export default withProrodinkiService;
