export const Disabled_if = {
        oncologist:['no_dermatoscopy', 'onetime_dermatoscopy', 'quarterly_dermatoscopy', 'semiannual_dermatoscopy', 'yearly_dermatoscopy'],
        no_consultancy:['no_dermatoscopy', 'onetime_dermatoscopy', 'quarterly_dermatoscopy', 'semiannual_dermatoscopy', 'yearly_dermatoscopy'],
        sevice_impossible:['no_dermatoscopy', 'onetime_dermatoscopy', 'quarterly_dermatoscopy', 'semiannual_dermatoscopy', 'yearly_dermatoscopy', 'clinical_info','photo_finder',
        'dermatologist', 'oncologist', 'no_consultancy']
}
export const CaseControl ={
        dermatologist:{group:"dependants",level:2},
        oncologist:{group:"dependants",level:2},
        no_consultancy:{group:"dependants",level:2},
        no_dermatoscopy:{group:"dependants",level:3},
        onetime_dermatoscopy:{group:"dependants",level:3},
        quarterly_dermatoscopy:{group:"dependants",level:3},
        semiannual_dermatoscopy:{group:"dependants",level:3},
        yearly_dermatoscopy:{group:"dependants",level:3},
        clinical_info:{group:"master",level:1},
        photo_finder:{group:"master",level:4},
        sevice_impossible:{group:"dependants",level:1},
}

