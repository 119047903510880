import React from "react";
import {
    TextField,
    Popover,
    InputAdornment,
    IconButton
} from "@mui/material";
import DateRangeIcon from "@mui/icons-material/DateRange";
import { withStyles } from "@mui/styles";
import moment from "moment";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import ru from "date-fns/locale/ru";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

const styles = {
    calendarWrapper: {
        padding: "16px"
    }
};

class DateRangePicker extends React.Component {
    dateFormat = "DD/MM/YYYY";
    constructor(props){
        super(props);
        //console.log("DateRangePicker props",props)
        this.state = {
            displayCalendar: false,
            inputValue: this.getInputValue(this.props.period.dateRange.fromDate, this.props.period.dateRange.toDate),
            anchorEl: null,
            fromDate: this.props.period.dateRange.fromDate,
            toDate: this.props.period.dateRange.toDate,
            changeDateRange: this.props.period.onChange
        };
    }
    getInputValue = (startDate, endDate)=>{
        let inputValue = "";
        if (startDate) inputValue += moment(startDate).format(this.dateFormat);
        if (endDate) inputValue += " - " + moment(endDate).format(this.dateFormat);
        //console.log("DateRangePicker getInputValue",startDate,endDate)
        return inputValue
    }

    onAdornmentClick = e => {
        this.setState({ displayCalendar: true, anchorEl: e.currentTarget });
    };

    onInputChange = e => {
        const inputValue = e.target.value;
        const { fromDate, toDate } = this.processInputValue(inputValue);

        this.setState({ inputValue, fromDate, toDate });
    };

    onPopoverClose = (e, reason) => {
        //console.log(reason);
        this.setState({ displayCalendar: false, anchorEl: null });
        this.state.changeDateRange(this.state.fromDate, this.state.toDate)
    };

    onSelectDateRanges = ({ selection }) => {
        let { startDate, endDate } = selection;

        startDate = moment(startDate);
        startDate = startDate.isValid() ? startDate.toDate() : undefined;

        endDate = moment(endDate);
        endDate = endDate.isValid() ? endDate.toDate() : undefined;

        let inputValue = this.getInputValue(startDate, endDate);

        this.setState({ fromDate: startDate, toDate: endDate, inputValue });
    };


    processInputValue(value) {
        let [fromDate, toDate] = value.split("-").map(elm => elm.trim());

        fromDate = moment(fromDate, this.dateFormat);
        fromDate = fromDate.isValid() ? fromDate.toDate() : undefined;

        toDate = moment(toDate, this.dateFormat);
        toDate = toDate.isValid() ? toDate.toDate() : undefined;

        return { fromDate, toDate };
    }

    render() {
        const { classes } = this.props;

        return (
            <div>
                <TextField
                    label="Период"
                    fullWidth={true}
                    value={this.state.inputValue}
                    InputProps={{readOnly: true,
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton sx={{ml:3}} onClick={this.onAdornmentClick}>
                                    <DateRangeIcon />
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                    onClick={this.onAdornmentClick}
                />
                <Popover
                    open={this.state.displayCalendar}
                    anchorEl={this.state.anchorEl}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left"
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "left"
                    }}
                    onClose={this.onPopoverClose}
                >
                    <div className={classes.calendarWrapper}>
                        <DateRange
                            ranges={[
                                {
                                    startDate: this.state.fromDate,
                                    endDate: this.state.toDate,
                                    key: "selection"
                                }
                            ]}
                            onChange={this.onSelectDateRanges}
                            locale = {ru}
                            staticRanges={undefined}
                            inputRanges={undefined}
                            maxDate={new Date()}
                            showMonthAndYearPickers={true}
                            moveRangeOnFirstSelection={false}
                            showDateDisplay={false}
                            scroll={{ enabled: true }}
                            months={2}
                            direction="horizontal"
                        />
                    </div>
                    <Box sx={{display:'flex', flexDirection:'row', justifyContent:'flex-end', my:1, mr:5}} >
                        <Button size="small" onClick={(e)=>this.onPopoverClose(e)}>Применить</Button>
                    </Box>
                </Popover>
            </div>
        );
    }
}
export default withStyles(styles, { name: "DateRangePicker" })(DateRangePicker);
