import React, {Component, Fragment, useEffect, useState} from "react";
import withProrodinkiService from "../../components/hoc/with-prorodinki-service";
import {baseUrl} from "../../config"
import {storageUrl} from "../../config"
import Consultation from "./Consultation";
import {MDBContainer, MDBIcon} from "mdbreact";
import {Diagnoses} from "../../components/diagnoses";
import Case from "../case";
import FormControl  from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import Input from "@mui/material/Input";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";

const ConsultationController = props =>{

  const initialDiagnose = {id: null, background:"#FFFFFF"}


    const [neoplasmData, setNeoplasmData] = useState(null)
    const [loadingStatus, setLoadingStatus] = useState(true)
    const [error, setError] = useState({isError:false, errorMessage:null})
    const [newCaseTrigger, setNewCaseTrigger] = useState(false)
    const [currentIndex, setCurrentIndex] = useState(0)
    const [messageSelectValue, setMessageSelectValue] = useState(0)
    const [messageText, setMessageText] = useState("")
    const [currentCaseId, setcurrentCaseId] = useState(props.caseId)
    const [requestCounter, setRequestCounter] = useState(0)
    const [request, setRequest] = useState(0)
    const [consType, setConsType] = useState(null)
    const [requestNumber, setRequestNumber] = useState(null)
    const [numberDisabled, setNumberDisabled] = useState(true)
    const [findButtonDisabled, setFindButtonDisabled] = useState(true)

  useEffect( () => {
      async function getConsultationFromServer()
      {
          let response = null
          response = consType==='outpatient'?
              await (props.prorodinkiService.getConsultationCase()):
              consType==='video'?
                  await (props.prorodinkiService.getConsultationCaseByRequest(requestNumber)):
                  {hasError:false, data:"", error:{}}
        //console.log('response: ',response)
        processResponse(response)
      }
      // console.log('useEffect', consType)
      getConsultationFromServer();
    },[newCaseTrigger]
  )

    const processResponse  = (response) => {
        if (!response.hasError && response.data === "") {
            response.hasError = true
            response.error.message = "Данные не обнаружены"
        }
        if (response.hasError) {
            setError({isError:true, errorMessage:response.error.message})
            setNeoplasmData(null)
            //console.log('Error returned:', response.error)
        } else {
            //console.log('processResponse Response:', response)
            response.data.neoplasm.caseImages.forEach(caseImage=>{
                caseImage.opinions.sort((op1,op2)=>{return (op1.date-op2.date)})
                const nnOpinions = caseImage.opinions.filter(opinion=>(!!opinion.nnResults))
                caseImage.nnResults = {"diagnosis":"","confidence":0.0,"caseObjects":[]}
                if ((nnOpinions.length) >0){
                    caseImage.nnResults = JSON.parse(nnOpinions[nnOpinions.length-1].nnResults)
                }
                // console.log("caseImage.nnResults",caseImage.nnResults)

            })
            setError({isError:false, errorMessage:null})
            setCurrentIndex(response.data.neoplasm.currentIndex)
            setcurrentCaseId(response.data.neoplasm.caseImages[response.data.neoplasm.currentIndex].id)
            setNeoplasmData(response.data.neoplasm)
            setRequestCounter(response.data.requestCounter)
            setRequest(response.data.consRequest)
        }
        setLoadingStatus(false)
        return (!response.hasError)
    }

    const getNnResults = ()=> {
        return !!neoplasmData.caseImages && !!neoplasmData.caseImages[currentIndex]? neoplasmData.caseImages[currentIndex].nnResults:[];
    }
    const opinions = () => ({
        columns: [
            {
                label: "Эксперт",
                field: "name",
            },
            {
                label: "Дата",
                field: "opinionDate",
            },
            {
                label: "Диагноз",
                field: "Diagnose",
            },
            {
                label: "Рекомендация отправлена",
                field: "Final",
            },
        ],
        rows: (!neoplasmData || !neoplasmData.caseImages[currentIndex])? [] :neoplasmData.caseImages[currentIndex].opinions.map(item =>(
            {
                name: item.expert.fio,
                opinionDate: new Date(item.date).toLocaleDateString('ru-RU',{hour:'2-digit', minute:'2-digit'}),
                Diagnose: item.diag.diagName,
                Final: <MDBIcon key="status" icon={item.finalOpinion? "check": "minus"} size="1x" className="mr-2 grey-text" aria-hidden="true" />,
            })
        )
    });
    const finalOpinion = (el => {
        const items = el.opinions.filter(opinion => opinion.finalOpinion===true)
        return items.length > 0? items[0]: null
    })
    const attributes = () => {
        return ({
            columns: [
                {
                    label: "Возраст",
                    field: "age",
                },
                {
                    label: "Пол",
                    field: "sex",
                },
                {
                    label: "Фототип",
                    field: "phototype",
                },

                {
                    label: "Ожоги",
                    field: "burns",
                },
                {
                    label: "Локализация",
                    field: "location",
                },
                {
                    label: "Когда возникло",
                    field: "beginning",
                },
            ],
            rows: neoplasmData===null? []: [
                {
                    age: new Date().getFullYear() - neoplasmData.patient.year,
                    sex: neoplasmData.patient.sex? "Женский": "Мужской",
                    photoType: neoplasmData.patient.photoType,
                    burns:neoplasmData.patient.wereSunburn? "Были": "Не было",
                    location: neoplasmData.location.description,
                    beginning: neoplasmData.beginning.description
                },
            ],
        })};

    function right(s, symbols) {
        return s.substring(s.length-symbols,s.length);
    }

    const images = neoplasmData===null? []: neoplasmData.caseImages.map(el => {
        const opinion = finalOpinion(el)
        const imageDate= new Date(el.imageDate)
        const fullYear = String(imageDate.getFullYear());
        const month = right('0'+ String(imageDate.getMonth()+1),2);
        const days = right('0'+ String(imageDate.getDate()),2);
        const originalName = `${storageUrl}/img/${fullYear}/${month}/${days}/${el.filename.replace('.jpg','_original.jpg')}`
        //console.log('originalName ', originalName)
        return {
            src:`${baseUrl}/img/${el.filename}`,
            originalName,
            diagName: opinion===null? "Не определено": opinion.diag.diagName
        }})

    const onChange = index => {
        setCurrentIndex(index)
    }
    const getMessageText = ()=>{
        return messageText
    }
    const messageTextPersist = (event)=>{
        // console.log('messageTextPersist event',event)
        setMessageText(event.target.value)
    }

    const commitConclusion =async (action)=> {
        await(props.prorodinkiService.postConclusion({
            id:null,
            reqId:request.id,
            caseId:neoplasmData.caseImages[neoplasmData.caseImages.length-1].id,
            content:messageText,
            action:action,
        }));
        setMessageText("")
        setNewCaseTrigger(!newCaseTrigger)
    }
    const consTypeChanged = (e)=>{
        setConsType(e.target.value)
        setNumberDisabled(e.target.value!=='video')
        setFindButtonDisabled(false)
    }
    const getRequest = ()=>{
        // console.log('getRequest')
        setNewCaseTrigger(!newCaseTrigger)
    }


    return (
        <MDBContainer className="py-5" >
            <FormControl component="fieldset">
                <FormLabel component="legend">Тип консультации</FormLabel>
                <RadioGroup
                    row
                    aria-label="cons_type"
                    //defaultValue="outpatient"
                    name="radio-buttons-group"
                    onChange={consTypeChanged}
                >
                    <FormControlLabel value="outpatient" control={<Radio />} label="Заочная" />
                    <FormControlLabel value="video" control={<Radio />} label="Видео" />
                </RadioGroup>
                <Box sx={{ flexDirection: 'row', justifyContent: 'space-between'}}>
                    <Input disabled={numberDisabled} placeholder="Номер заявки" onChange={(e)=>{
                        setRequestNumber(e.target.value)
                    }} />
                    <Button disabled={findButtonDisabled} variant="outlined" sx={{ margin: 2}} onClick={getRequest}>Найти</Button>
                </Box>
            </FormControl>
            {
                (!neoplasmData || loadingStatus || requestCounter ===0)?
                <h1>Данных не обнаружено</h1> :
                <Consultation
                      title             ={`Заявок всего - ${requestCounter}`}
                      opinions          ={opinions}
                      caseId            = {neoplasmData.caseImages[neoplasmData.currentIndex].id}
                      caseIndex         = {currentIndex}
                      error             ={error}
                      attributes        ={attributes}
                      images            = {images}
                      onChange          = {onChange}
                      getNnResults      = {getNnResults}
                      message = {{getMessageText, messageTextPersist}}
                      onCommit = {commitConclusion}
                />
            }
        </MDBContainer>
    )
}

export default withProrodinkiService(ConsultationController)
