import React, { Component } from "react";
import {MDBCol, MDBContainer, MDBRow} from "mdbreact";
import "../../index.css";
import NavBar from "../navbar"
import withProrodinkiService from "../hoc/with-prorodinki-service";
import LoginCard from "../../pages/logincard";

class App extends Component {
    state = {user:this.props.prorodinkiService.getUser()}

    setUser = () => {
        const user=  this.props.prorodinkiService.getUser()
        this.setState({user:user})
    }

    signOut = () => {
        this.setState({user:null})
        this.props.prorodinkiService.removeUser()
    }

    render() {
        const noLogin = (this.state.user == null || this.state.user.fio==null)
        return (
          <MDBContainer fluid className="mt-3">
            <MDBRow center style={{ height: "100vh" }}>
                <MDBCol>
                    {noLogin ?
                        <LoginCard setUser={this.setUser}/> :
                        <NavBar signOut = {this.signOut} user={this.state.user}/>
                    }
                </MDBCol>
            </MDBRow>
          </MDBContainer>
        );
    }
}

export default withProrodinkiService(App)
