import Box from "@mui/material/Box";
import React, {useState} from "react";
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormGroup from '@mui/material/FormGroup';
import RadioGroup from '@mui/material/RadioGroup';
import Checkbox from '@mui/material/Checkbox';
import Radio from '@mui/material/Radio';
import Evidence from "./Evidence"

const SignGroups2 = (props) =>{
    const {evidence} = props

    const onRadioGroupCheck = (event,value)=>{
        evidence.check(event.target.name,value,true)
        for (const key of Object.keys(evidence.checked[event.target.name])) {
            if (key!==value && !!evidence.checked[event.target.name][key]){
                evidence.check(event.target.name,key,false)
            }
        }
    }
    const onSurfaceCheck = (event)=>{
        evidence.check('surface_group',event.target.value,event.target.checked)
    }
    const ShowRadioGroup =(props)=>{
        const {groupName} = props
        const controls = Object.keys(Evidence[groupName]).filter(el=>el!=='label')
        return(
            <FormControl sx={{mb:1, borderBottom: 1,}}>
                <FormLabel  color='primary' id={groupName+'_label'}>{Evidence[groupName].label}</FormLabel>
                <RadioGroup  name={groupName} onChange={onRadioGroupCheck} value={checkedRadioValue(evidence.checked[groupName])}>
                    {controls.map((el)=> (<FormControlLabel key={el} sx={{mx:1}} value={el} control={<Radio/>} label={Evidence[groupName][el].label}/>))}
                </RadioGroup>
            </FormControl>
        )
    }
    const isChecked = (group,el)=>{
        return !!evidence.isChecked(group,el)
    }
    const checkedRadioValue = (group)=>{
        const radioValue = Object.keys(group).find(key=> !!group[key] && key!=='label')
        return radioValue
    }
    const ShowCheckBoxGroup =(props)=>{
        const {groupName, onChange} = props
        const controls = Object.keys(Evidence[groupName]).filter(el=>el!=='label')
        return(
            <FormGroup sx={{mb:1, borderBottom: 1}}>
                <FormLabel  color='primary' id={groupName+'_label'} >{Evidence[groupName].label}</FormLabel>
                {controls.map((el)=> (<FormControlLabel key={el} sx={{mx:1}} value={el} control={<Checkbox/>} checked={isChecked(groupName,el)} label={Evidence[groupName][el].label} onChange={onChange}/>))}
            </FormGroup>
        )
    }


    return (
        <Box sx={{display:'flex', flexDirection:'row'}}>
            <Box sx={{display:'flex', flexDirection:'column', }}>
                <ShowRadioGroup groupName={"symmetry_group"}/>
                <ShowCheckBoxGroup groupName={"surface_group"} onChange={onSurfaceCheck}/>
                <ShowRadioGroup groupName={"form_group"}/>
                <ShowRadioGroup groupName={"contour_group"}/>
            </Box>
            <Box sx={{display:'flex', flexDirection:'column'}}>
                <ShowRadioGroup groupName={"volume_group"}/>
                <ShowRadioGroup groupName={"border_group"}/>
                <ShowRadioGroup groupName={"color_group"}/>
            </Box>
        </Box>
    )
}
export default SignGroups2
