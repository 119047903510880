import React from "react";
import ReactDOM from "react-dom";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap-css-only/css/bootstrap.min.css";
import "mdbreact/dist/css/mdb.css";
import "./index.css";
import App from "./components/app";

import registerServiceWorker from './registerServiceWorker';

import ErrorBoundry from './components/error-boundry';
import ProrodinkiService from './services/prorodinki-service';
import { ProrodinkiServiceProvider } from './components/prorodinki-service-context';


const prorodinkiService = new ProrodinkiService();

ReactDOM.render(
    <ErrorBoundry>
        <ProrodinkiServiceProvider value={prorodinkiService}>
            <link
                rel="stylesheet"
                href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"
            />
          <App />
        </ProrodinkiServiceProvider>
    </ErrorBoundry>,
    document.getElementById('root')
);

registerServiceWorker();
