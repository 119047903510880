import React, {useEffect, useRef} from 'react';
import ReactDOM,{ render } from 'react-dom';
import { Stage, Layer, Rect} from 'react-konva';
import {withRouter} from "react-router-dom";
import withProrodinkiService from "../../components/hoc/with-prorodinki-service";
import {baseUrl} from "../../config"
import URLImage from "./UrlImage"
import ErrorCheck from "../../components/error-check";
import {MDBBtn, MDBBtnGroup, MDBCol, MDBContainer, MDBIcon, MDBRow} from "mdbreact";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
const MarkObjects = (props) => {
    const [currentRect, setCurrentRect] = React.useState({isDrawing:false, x:0, y:0, width:0, height:0});
    const [rectangles, setRectangles] =React.useState([])
    const [image, setImage] =React.useState({src:'', detected:[], fileName:'', cropName:'', mode:'crops', imageId:null, totalUserCount:null, todayUserCount:null, width:0, height:0})
    const [autoMode, setAutoMode] =React.useState(true)
    const mainRectRef = React.useRef(null);

    const topX =()=> !!mainRectRef.current? mainRectRef.current.getBoundingClientRect().x:0
    const topY =()=> !!mainRectRef.current? mainRectRef.current.getBoundingClientRect().y:0
    const viewImageWidth=500

    const startDrawRect = (e) => {
        setCurrentRect({isDrawing:true, x:e.evt.x, y:e.evt.y, width:0, height:0})
    };
    const endDrawRect = (e) => {
        const rect= {x:currentRect.x-topX(), y:currentRect.y-topY(), width:e.evt.x - currentRect.x, height:e.evt.y - currentRect.y}
        setCurrentRect({isDrawing:false, x:currentRect.x, y:currentRect.y, width:e.evt.x - currentRect.x, height:e.evt.y - currentRect.y})
        if(Math.abs(e.evt.x - currentRect.x)>10 || Math.abs(e.evt.y - currentRect.y)>10) {
            setRectangles([...rectangles,
                {leftX:(rect.width>0?rect.x:(rect.x+rect.width))/(image.scale*image.width),
                    topY:(rect.height>0?rect.y:rect.y+rect.height)/(image.scale*image.height),
                    rightX:(rect.width>0?(rect.x+rect.width):rect.x)/(image.scale*image.width),
                    bottomY:(rect.height>0?(rect.y+rect.height):rect.y)/(image.scale*image.height)
                }
            ]);
        }
    };

    const DrawRect = (e) => {
        setCurrentRect({isDrawing:currentRect.isDrawing, x:currentRect.x, y:currentRect.y, width:e.evt.x - currentRect.x, height:e.evt.y - currentRect.y})
    };
    React.useEffect(() => {
        getMarkups().then(() =>{} )
    }, []);

    const getMarkups = async ()=> {
        let response =await props.prorodinkiService.getObjectToMark();
        setImage({src:`${baseUrl}/img/${response.data.fileName}`,
            detected:[...response.data.rectangles],
            fileName:response.data.fileName,
            cropName:response.data.cropName,
            mode:response.data.mode,
            imageId: response.data.imageId,
            width:response.data.width,
            height:response.data.height,
            scale: viewImageWidth/response.data.width,
            totalUserCount: response.data.totalUserCount,
            todayUserCount: response.data.todayUserCount})
    }
    const autoModeChange= ()=>setAutoMode(!autoMode)
    const findAndSwapRectangles = (e)=>{
        let moveDetected =[]
        let moveRectangles =[]
        let detected = image.detected
        let drew = rectangles
        const point = {x:e.evt.x- topX(), y:e.evt.y- topY()}
        console.log("findAndSwapRectangles point:", point)
        console.log("findAndSwapRectangles image:", image)
        selectIndexes(detected, moveDetected, point)
        selectIndexes(drew, moveRectangles, point)
        console.log("findAndSwapRectangles after selectIndexes")

        let swappedArrays = null
        if(autoMode){
            swappedArrays = swapRectangles({from:detected, to:drew},moveDetected)
            detected= swappedArrays.from
            drew= swappedArrays.to
        }

        swappedArrays = swapRectangles({from:drew, to:detected},moveRectangles)

        detected= swappedArrays.to
        drew= swappedArrays.from
        console.log("findAndSwapRectangles after swap drew", drew)
        console.log("findAndSwapRectangles after swap detected", detected)
        setRectangles(drew)
        const img={...image, detected:detected}
        console.log("findAndSwapRectangles img:",img)
        setImage({...image, detected:detected})
        console.log("findAndSwapRectangles finished")

    }
    const selectIndexes = (arr, selectedIndexes,point)=>{
        arr.forEach((el,index)=> {
            if(isPointInRectangle(el, point)) {
                selectedIndexes.push(index)
            }
        })
    }
    const swapRectangles = (swapArrays, indexes)=>{
        indexes.sort((a,b)=>b-a)
        indexes.forEach(index=>{
            swapArrays.to = [...swapArrays.to,swapArrays.from[index]]
            swapArrays.from = [...swapArrays.from.slice(0,index), ...swapArrays.from.slice(index+1)]
        })
        return swapArrays
    }
    const isPointInRectangle = (rect, point)=>{
        const kw= image.scale*image.width
        const kh= image.scale*image.height
        return point.x>=rect.leftX*kw && point.x<=rect.rightX*kw && point.y>=rect.topY*kh && point.y<=rect.bottomY*kh
    }

    const save = async(e)=> {
        console.log('save ', e.target.name)
        let response =await props.prorodinkiService.postMarkups(image.imageId, image.fileName, image.mode, image.width, image.height, rectangles, e.target.name);
        getMarkups().then(() =>{} )
        setRectangles([]);
    }
    const rectangleX = rect=>rect.leftX*image.scale*image.width;
    const rectangleY = rect=>rect.topY*image.scale*image.height;
    const rectangleWidth = rect=>(rect.rightX - rect.leftX)*image.scale*image.width;
    const rectangleHeight = rect=>(rect.bottomY - rect.topY)*image.scale*image.height;
    return (
        <MDBContainer className="py-5" >
            <MDBCol md="6">
                <MDBContainer className="mt-2 text-left">
                    <h5>{`Вы обработали изображений: ${image.totalUserCount}. Из них сегодня: ${image.todayUserCount}`}</h5>
                    <FormControlLabel
                        checked={autoMode}
                        control={<Checkbox color="primary" />}
                        onChange={autoModeChange}
                        label="Предварительная разметка"
                        labelPlacement="end"
                    />
                </MDBContainer>
                {/*<div ref={mainPhotoRef}>*/}
                {/*    <img alt="photo" src={image.src} style={{width:500}}/>*/}
                {/*</div>*/}
                <div ref={mainRectRef}>
                    <Stage width={viewImageWidth} height={image.height*image.scale}>
                    <Layer>
                        <URLImage src={image.src} width={viewImageWidth} height={image.height*image.scale} />
                        {/*{   image.mode==='originals'?*/}
                        {/*    <URLImage src={`${baseUrl}/img/${image.cropName}`} x={510}/>:null*/}
                        {/*}*/}

                        {   autoMode?
                            image.detected.map((rectangle,index)=>(
                                <Rect
                                    key = {index}
                                    x={rectangleX(rectangle)}
                                    y={rectangleY(rectangle)}
                                    width={rectangleWidth(rectangle)}
                                    height={rectangleHeight(rectangle)}
                                    stroke="green"
                                />
                            )):null
                        }
                        {
                            rectangles.map((rectangle,index)=>(
                                <Rect
                                    key = {index}
                                    x={rectangleX(rectangle)}
                                    y={rectangleY(rectangle)}
                                    width={rectangleWidth(rectangle)}
                                    height={rectangleHeight(rectangle)}
                                    stroke="red"
                                />
                            ))
                        }
                        { currentRect.isDrawing?
                            <Rect
                                x={currentRect.x-topX()}
                                y={currentRect.y-topY()}
                                width={currentRect.width}
                                height={currentRect.height}
                                stroke="red"
                            />:null
                        }
                        <Rect
                            width={image.scale*image.width}
                            height={image.scale*image.height}
                            stroke="black"
                            onMouseDown={startDrawRect}
                            onMouseUp={endDrawRect}
                            onMouseMove={DrawRect}
                            onDblClick={findAndSwapRectangles}
                        />
                    </Layer>
                </Stage>
                </div>
                <MDBBtnGroup className="align-items-center">
                    <MDBBtn color="primary" size="lg" name="ok"
                            onClick={save} >
                        Сохранить <MDBIcon icon="check" className="ml-2"/>
                    </MDBBtn>
                    <MDBBtn color="blue-grey" size="lg" className="ml-3" name="NoMarkup"
                            onClick={save}>
                        Не сохранять <MDBIcon icon="times" className="ml-2"/>
                    </MDBBtn>
                </MDBBtnGroup>
            </MDBCol>
        </MDBContainer>
    );
};

export default withRouter(withProrodinkiService(MarkObjects))
