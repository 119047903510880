export const  Diagnoses =
    [
        {
            id:"NEVUS",
            name:"Невус",
            key:"1",
            severity:"SUPERVISION",
            color:"success",
            background:"#c6e0b4",
            type:"BENIGN",
            short:"Невус"
        },
        {
            id:"SK",
            name:"Себорейная кератома",
            key:"2",
            severity:"SUPERVISION",
            color:"success",
            background:"#c6e0b4",
            type:"BENIGN",
            short:"СК"
        },
        {
            id:"HEMANGIOMA",
            name:"Гемангиома",
            key:"3",
            severity:"SUPERVISION",
            color:"success",
            background:"#c6e0b4",
            type:"BENIGN",
            short:"Геман."
        },
        {
            id:"BKRK",
            name:"Базально-клеточный рак",
            key:"4",
            severity:"EMERGENCY",
            color:"danger",
            background:"#f8c0ad",
            type:"MALIGNANT",
            short:"БКРК"
        },
        {
            id:"PKRK",
            name:"Плоскоклеточный рак",
            key:"5",
            severity:"EMERGENCY",
            color:"danger",
            background:"#f8c0ad",
            type:"MALIGNANT",
            short:"ПКРК"
        },
        {
            id:"MELANOMA",
            name:"Меланома",
            key:"6",
            severity:"EMERGENCY",
            color:"danger",
            background:"#f8c0ad",
            type:"MALIGNANT",
            short:"Мелан."
        },
        {
            id:"DERMATOFIBROMA",
            name:"Дерматофиброма",
            key:"7",
            severity:"SUPERVISION",
            color:"success",
            background:"#c6e0b4",
            type:"BENIGN",
            short:"Дер.фиб."
        },
        {
           id:"ACTINIC_KERATOSIS",
           name:"Актинический кератоз",
            key:"8",
           severity:"SUPERVISION",
           color:"warning",
           background:"#ffe699",
            type:"MALIGNANT",
            short:"Ак.кер."
        },
        {
            id:"NOT_A_NEOPLASM",
            name:"Не новообразование",
            key:"9",
            severity:"SUPERVISION",
            color:"success",
            background:"#c6e0b4",
            type:"NOT_A_NEOPLASM",
            short:"Неновообр."
        },
        {
            id:"UNRECOGNIZED",
            name:"Снимок не распознается",
            key:"0",
            severity:"REPEAT",
            color:"info",
            background:"#b4c6e7",
            type:"UNRECOGNIZED",
            short:"Нерасп."
        },
        {
            id:"OUT_OF_SCOPE",
            name:"Вне области применения",
            key:"10",
            severity:"REPEAT",
            color:"info",
            background:"#b4c6e7",
            type:"OUT_OF_SCOPE",
            short:"Вне области"
        },
        {
            id:"UNDEFINED",
            name:"Не определено",
            key:"101",
            severity:"WAIT",
            color:"info",
            background:"#b4c6e7",
            type:"UNDEFINED",
            short:"Не опред."
        }
    ]
