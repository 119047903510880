import React from 'react';

const {
  Provider: ProrodinkiServiceProvider,
  Consumer: ProrodinkiServiceConsumer
} = React.createContext();

export {
  ProrodinkiServiceProvider,
  ProrodinkiServiceConsumer
};
