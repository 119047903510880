import React, {Component, Fragment, useEffect, useState} from "react";
import withProrodinkiService from "../../components/hoc/with-prorodinki-service";
import {baseUrl} from "../../config"
import Case from "../case/case";
import {MDBIcon} from "mdbreact";
import {Diagnoses} from "../../components/diagnoses";
import VerificationCase from "./VerificationCase";

const VerificationController = props =>{
    // console.log("verification props ", props)
    const initialDiagnose = {id: null, background:"#888888"}

    const [neoplasmData, setNeoplasmData] = useState(null)
    const [loadingStatus, setLoadingStatus] = useState(true)
    const [error, setError] = useState({isError:false, errorMessage:null})
    const [selectedDiagnose, setSelectedDiagnose] = useState(initialDiagnose)
    const [newCaseTrigger, setNewCaseTrigger] = useState(false)
    const [currentIndex, setCurrentIndex] = useState(0)
    const [currentCaseId, setcurrentCaseId] = useState(props.caseId)

    const getCaseFromServer = async  () =>
    {
        setLoadingStatus(true)
        let response = await (props.prorodinkiService.getVerificationCase());
        processResponse(response)
    }

    useEffect(  () => {
            getCaseFromServer().then(()=> {} )
        },
        [newCaseTrigger]
    )

    const setDiagnose = async (caseId, diagnosis) => {
        setLoadingStatus(true)
        // console.log('setDiagnose ', caseId, diagnosis)
        const response = await(props.prorodinkiService.putVerificationDiagnose(caseId, diagnosis, neoplasmData.caseImages[currentIndex].boundaries));
        processResponse(response)
    }

    const processResponse  = (response) => {
        if (!response.hasError && response.data === "") {
            response.hasError = true
            response.error.message = "Данные не обнаружены"
        }
        if (response.hasError) {
            setError({isError:true, errorMessage:response.error.message})
            setSelectedDiagnose({id: null, background:null})
            setNeoplasmData(null)
            //console.log('Error returned:', response.error)
        } else {
            // console.log('processResponse Response:', response)
            setError({isError:false, errorMessage:null})
            setSelectedDiagnose(initialDiagnose)
            setCurrentIndex(response.data.currentIndex)
            setcurrentCaseId(response.data.caseImages[response.data.currentIndex].id)
            setNeoplasmData(response.data)
        }
        setLoadingStatus(false)

        return (!response.hasError)
    }

    const attributes = () => {
        return ({
            columns: [
                {
                    label: "Возраст",
                    field: "age",
                },
                {
                    label: "Пол",
                    field: "sex",
                },
                {
                    label: "Фототип",
                    field: "phototype",
                },

                {
                    label: "Ожоги",
                    field: "burns",
                },
                {
                    label: "Локализация",
                    field: "location",
                },
                {
                    label: "Когда возникло",
                    field: "beginning",
                },
            ],
            rows: neoplasmData===null? []: [
                {
                    age: new Date().getFullYear() - neoplasmData.patient.year,
                    sex: neoplasmData.patient.sex? "Женский": "Мужской",
                    photoType: neoplasmData.patient.photoType,
                    burns:neoplasmData.patient.wereSunburn? "Были": "Не было",
                    location: neoplasmData.location.description,
                    beginning: neoplasmData.beginning.description
                },

            ],
        })};

    const diagnoseClick = (item) => {
        // setSelectedDiagnose(item)
        // console.log(neoplasmData)
        setDiagnose(neoplasmData.caseImages[neoplasmData.currentIndex].id, item.id)
    }


    const images = (!neoplasmData || !(neoplasmData.caseImages) || !(neoplasmData.caseImages[currentIndex])) ? []:
        [{
            src:`${baseUrl}/img/${neoplasmData.caseImages[currentIndex].filename}`,
            diagName: "",
            boundaries: neoplasmData.caseImages[currentIndex].boundaries
        }]

    const onChange = index => {
        setCurrentIndex(index)
    }

    // console.log("Verification render", neoplasmData, currentIndex)
    return (  neoplasmData===null || loadingStatus? null:
        <VerificationCase title = {`Всего:${neoplasmData.totalCount}. Осталось:${neoplasmData.userCount} `}
              caseId = {neoplasmData.caseImages[neoplasmData.currentIndex].id}
              caseIndex = {0}
              error={error}
              selectedDiagnose={selectedDiagnose}
              attributes={attributes}
              diagnoseClick={diagnoseClick}
              images = {images}
              setDiagnose = {setDiagnose}
              onChange = {onChange}
              process={"VERIFICATION"}
         />)
}

export default withProrodinkiService(VerificationController)
