import React, {useEffect, useState} from 'react';
import ErrorCheck from "../../components/error-check";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Box from '@mui/material/Box';
import TableRowsIcon from '@mui/icons-material/TableRows';
import ViewComfyIcon from '@mui/icons-material/ViewComfy';
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Badge from '@mui/material/Badge';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import RequestPageOutlinedIcon from '@mui/icons-material/RequestPageOutlined';
import TaskOutlinedIcon from '@mui/icons-material/TaskOutlined';

import "../../index.css"
import {Diagnoses} from "../../components/diagnoses";
import CaseImageList from "./CaseImageList";
import CaseTable from "./CaseTable";
import FilterDiagnoses from "./FilterDiagnoses";
import Loader from "../../components/Loader/loader";
import DateRangePicker from "./DateRangePicker";

const CaseList2 = ({table, clickEvent, period, order, entries, onSort, onChangeFilter, statusFilter,requestMode,setDiagnose, diagnoseSameAs, dataPage}) =>{

    const {selectedEntries, pageEntries, onEntriesChanged} = entries

    const [forceRendering, setForceRendering] = useState(false)

    const setAllDiagnosesFilter =()=>{
        const diagFilter=[]
        Diagnoses.forEach(el=> diagFilter[el.id]=true)
        return diagFilter
    }

    const initDiagnosesFilter = ()=>{
        let diagFilter=[]
        diagFilter["expert"] = setAllDiagnosesFilter()
        diagFilter["neuro"] = setAllDiagnosesFilter()
        //console.log("initDiagnosesFilter diagFilter", diagFilter)
        return diagFilter
    }
    const saveDiagnosesFilter = (diagFilter)=>{
        localStorage.setItem('DiagnosesFilter',JSON.stringify(
            Object.keys(diagFilter).map(type=>Object.keys(diagFilter[type]).map(key=> ({type,key,value:diagFilter[type][key]})))
        ))
    }

    const RestoreDiagnosesFilter = (diagFilter)=>{
        if(!!localStorage.getItem('DiagnosesFilter')){
            JSON.parse(localStorage.getItem('DiagnosesFilter'))
                .forEach((diagType)=>(diagType.forEach(el=>diagFilter[el.type][el.key]=el.value)))
        }
        //console.log("RestoreDiagnosesFilter diagFilter", diagFilter)
        saveDiagnosesFilter(diagFilter)
        return diagFilter
    }
    const initViewType =()=>{
        let vt = localStorage.getItem('ViewCaseListType')
        if (!vt){
            vt='GRID'
            localStorage.setItem('ViewCaseListType',vt)
        }
        return vt
    }

    //localStorage.removeItem('DiagnosesFilter')

    let initialDiagnosesFilter = RestoreDiagnosesFilter(initDiagnosesFilter())

    const [searchText, setSearchText] = useState("")
    const [diagnosesFilter, setDiagnosesFilter] = useState(initialDiagnosesFilter)
    const [viewType, setViewType] = useState(initViewType())
    const setAndSaveViewType = (value)=>{
        setViewType(value)
        localStorage.setItem('ViewCaseListType',value)
    }

    const resetCheckedItems = (data)=>{
        data.forEach(el=>{if (!!el.checkState) el.checkState=false})
    }

    useEffect(()=>{
        //console.log("CaseList2 useEffect Page 0")
        dataPage.setPage(0)
        resetCheckedItems(table.data)
        setForceRendering(!forceRendering)
    },[searchText, diagnosesFilter])


    const searchData= (data)=>{
        return !!searchText? data.filter(item=> item.id.toString().includes(searchText.trim())|| item.expertFio.includes(searchText.trim())):data
    }
    const filterData = (data)=>{
           //console.log("filterData data", data)
            const filteredData = requestMode.mode==='all'?
                data.filter(item => diagnosesFilter["expert"][item.humanDiagData.diagnosis] && diagnosesFilter["neuro"][item.neuralDiagnosis.diagnosis]):
                data
            //console.log("filterData filteredData", filteredData)
            return !!searchText ? searchData(filteredData) : filteredData
    }
    const onCloseFilterDiagnoses = ()=>{
        saveDiagnosesFilter(diagnosesFilter)
        setDiagnosesFilter({...diagnosesFilter})
    }

    const clickCase= (caseId)=>{
        clickEvent(caseId, filterData(table.data))
    }
    const handleRequestMode = (event, newRequestMode) => {
        if(!!newRequestMode) {
            requestMode.handler(newRequestMode);
        }
    };

    return (
        <>
           <Box sx={{mt:5, ml:5, display:'flex', flexDirection:'row', justifyContent: 'space-between', alignItems:"center", maxWidth:2500}}>
               <ToggleButtonGroup
                   value={requestMode.mode}
                   exclusive
                   onChange={handleRequestMode}
                   aria-label="request mode"
               >
                   <ToggleButton value="ordersOnly" aria-label="orders only">
                       {requestMode.count > 0 ?
                           <Badge badgeContent={requestMode.count} color="primary">
                               <RequestPageOutlinedIcon/>
                           </Badge>:
                           <RequestPageOutlinedIcon/>
                       }
                   </ToggleButton>
                   <ToggleButton value="all" aria-label="all">
                       <TaskOutlinedIcon />
                   </ToggleButton>
               </ToggleButtonGroup>
               {requestMode.mode==='all'?
               <Box sx={{display:'flex', flexDirection:'row', alignItems:"center", alignContent: 'center'}}>
                    <Box sx={{ml:5}}>
                        <DateRangePicker period={period} />
                    </Box>
                    <Box sx={{ml:5,display:'flex', justifyContent: 'center', flexDirection:'column', alignItems:"flex-start"}}>
                        <FormControlLabel
                            checked={!!statusFilter.pending}
                            value="pending"
                            control={<Checkbox color="primary" />}
                            onChange={onChangeFilter}
                            label= 'Ждущие'
                            labelPlacement="end"
                        />
                        <FormControlLabel
                            checked={!!statusFilter.controlOpinion}
                            value="controlOpinion"
                            control={<Checkbox color="primary" />}
                            onChange={onChangeFilter}
                            label= 'Контрольное мнение'
                            labelPlacement="end"
                        />
                    </Box>
                    <Box sx={{ml:5}}>
                        <FilterDiagnoses onClose={onCloseFilterDiagnoses} checkedDiags ={diagnosesFilter}/>
                    </Box>
                    <Box sx={{ml:5, display:'flex', flexDirection:'row', alignItems:"center", alignContent: 'center'}}>
                        <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                            <SearchIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                            <TextField id="standard-basic" variant="standard" defaultValue={searchText}
                                       onChange={event => {setSearchText( event.target.value)}}
                            />
                        </Box>
                   </Box>
               </Box>:null}
               <Stack sx={{width:"200px", justifyContent:"flex-end", alignItems:"center"}} direction="row">
                   <IconButton aria-label="table"  onClick={()=>setAndSaveViewType('TABLE')}>
                       <TableRowsIcon />
                   </IconButton>
                   <IconButton aria-label="grid" onClick={()=>setAndSaveViewType('GRID')}>
                       <ViewComfyIcon />
                   </IconButton>
               </Stack>
           </Box>


            <ErrorCheck error={table.error} message={table.errorMessage}>
                    {table.isLoading ? null:
                        (table.data ?
                            <Box>
                                {viewType==='GRID'?
                                    <CaseImageList
                                        imageData={filterData(table.data)}
                                        onImageClick={clickCase}
                                        setDiagnose={setDiagnose}
                                        diagnoseSameAs={diagnoseSameAs}
                                        dataPage={dataPage}
                                        resetChecked={resetCheckedItems}
                                    />:
                                    <CaseTable
                                        order = {order}
                                        imageData = {filterData(table.data)}
                                        clickEvent = {clickCase}
                                        onSort = {onSort}
                                    />
                                }
                            </Box>:
                            <h3> Данные отсутствуют </h3>
                        )
                    }
            </ErrorCheck>
            {table.isLoading ? <Loader/> : null}
        </>
    );
}
export default CaseList2
