import React, {Component, useEffect, useState} from 'react';
import withProrodinkiService from "../../components/hoc/with-prorodinki-service";
import {withRouter} from "react-router-dom"
import CaseList2 from "./CaseList2"
import {compareValues,subtractMonthes} from "../../utils"
import {Diagnoses} from "../../components/diagnoses";

const CaseListController = (props) =>{

    let all = 'Все'
    const pageEntries = [10, 25, 50, all]
    const initDateRange = ()=>{
        //localStorage.removeItem("CaseListPeriod")
        const parseToJSDates = (jsonDateRange)=>{
            return {...jsonDateRange, fromDate: new Date(jsonDateRange.fromDate),toDate: new Date(jsonDateRange.toDate)}
        }
        const initialDateRange = !!localStorage.getItem("CaseListPeriod")? parseToJSDates(JSON.parse(localStorage.getItem("CaseListPeriod"))):
        {
            fromDate : new Date(new Date().getTime() - 2*24*60*60*1000),            //Minus two days
            toDate: new Date(),
            today: true
        }
        //console.log("initDateRange1 ",initialDateRange)
        if(initialDateRange.today && initialDateRange.toDate.toDateString() !== new Date().toDateString()){
            initialDateRange.fromDate = new Date(new Date().getTime()-(initialDateRange.toDate.getTime()-initialDateRange.fromDate.getTime()));
            initialDateRange.toDate = new Date()
            localStorage.setItem("CaseListPeriod", JSON.stringify(initialDateRange))
        }
        //console.log("initDateRange2 ",initialDateRange)
        return initialDateRange
    }

    let initialParams = !localStorage.getItem('CaseListParams')?{
        order:['id','desc'],
        linesOnPage:0,
        pending:false,
        controlOpinion:false
    }: JSON.parse(localStorage.getItem('CaseListParams'));

    const [order, setOrder] = useState(initialParams.order)
    const [forceReload, setForceReload] = useState(true)

    const [dateRange, setDateRange] = useState(initDateRange())

    const [selectedEntries, setSelectedEntries] = useState(initialParams.linesOnPage)

    const [statusFilter, setStatusFilter] = useState({pending:initialParams.pending, controlOpinion:initialParams.controlOpinion})
    const [checkMode, setCheckMode] = useState(props.checkMode)

    const [requestMode, setRequestMode] = useState(!localStorage.getItem('RequestMode')?"all":localStorage.getItem('RequestMode'))
    const changeRequestMode =(value)=>{
        setRequestMode(value)
        localStorage.setItem('RequestMode', value)
    }

    if(checkMode!==props.checkMode){
        setCheckMode(props.checkMode)
    }

    const changeDateRange = (startDate,endDate) =>{
        const dateRange = {
            fromDate : startDate,
            toDate : endDate,
            today: (!!endDate && endDate.toDateString() === new Date().toDateString())
        }
        localStorage.setItem("CaseListPeriod", JSON.stringify(dateRange))
        setTable({
            isLoading: true,
            data: [],
            error: false,
            errorMessage: null,
        })
        setDateRange(dateRange)
    }

    const changeEntries = (e) =>{
        setSelectedEntries(e.target.value)
        initialParams.linesOnPage = e.target.value
        localStorage.setItem('CaseListParams', JSON.stringify(initialParams));
        setTable({
            isLoading: true,
            data: [],
            error: false,
            errorMessage: null,
        })
    }
    const changeStatusFilter = (e) =>{
        const status=statusFilter
        status[e.target.value]=e.target.checked
        //console.log("status", status)
        setStatusFilter({...status})
        initialParams[e.target.value] = e.target.checked
        localStorage.setItem('CaseListParams', JSON.stringify(initialParams));
        setTable({
            isLoading: true,
            data: [],
            error: false,
            errorMessage: null,
        })
    }

    const [table, setTable] = useState( {
        isLoading: true,
        error:false,
        errorMessage:null,
        data: [],
    })


    useEffect( () => {
        async function fetchData(ord) {
          setTable({...table,...{data:[],isLoading:true}})
          let response = requestMode==='all'?
              await props.prorodinkiService.getCasesList2(dateRange.fromDate, dateRange.toDate):
              await props.prorodinkiService.getWaitingOrders()
            if (response.hasError) {
              setTable({
                  isLoading: false,
                  data: [],
                  error: true,
                  errorMessage: response.error.message,
              })
              //console.log('Error returned:', response.error)
          } else {
              props.prorodinkiService.caseList.sort(compareData(ord[0],ord[1]))
              props.prorodinkiService.caseList = props.prorodinkiService.caseList.filter(el=>{
                  return ((checkMode==="validation" && !!el.processCode && el.processCode==="VALIDATION")||
                      (checkMode!=="validation" && el.processCode!=="VALIDATION"))
              })
              if (requestMode==='all' && (!!statusFilter.pending || !!statusFilter.controlOpinion)) {
                  props.prorodinkiService.caseList = props.prorodinkiService.caseList.filter(el => {
                      return statusFilterResult(el)
                  })
              }


              setTable({
                  isLoading: false,
                  data: props.prorodinkiService.caseList, //response.data,
                  error: false,
                  errorMessage: null,
              })
          }
        }
        fetchData(order)
        // const  fetchInterval = setInterval(fetchData, 60000, order)
        // return () => clearInterval(fetchInterval)
        },
        [requestMode, dateRange, selectedEntries, statusFilter, checkMode, forceReload]
    )
    const [page,setPage]=useState(0)
    //console.log("init caseController page",page)
    useEffect(()=>{
        //console.log("CaseListController useEffect Page 0")
        setPage(0)
    },[requestMode,dateRange,statusFilter])

    const statusFilterResult = el => (
        (!!statusFilter.pending && (el.caseStatus==='PENDING' && el.finalDiagnosis.diagnosis==='UNDEFINED' && el.neuralDiagnosis.diagnosis!=='UNDEFINED') )||
        (!!statusFilter.controlOpinion && el.orderState==='OPEN') ||
        (!statusFilter.pending && !statusFilter.controlOpinion)
    )
    const compareData = (key, direction='asc') =>{
        //console.log("sorting for", key, direction)
        const sortValue = (item, key) => {
            return (
              (key==="id" || key==="caseDate" || key==="expertFio" || key === "caseStatus")?
                item[key] :
                (key==="expertOpinion")?
                  item.humanDiagData.diagName :
                  (key==="neuralOpinion")?
                    item.neuralDiagnosis.diagName :
                    (key === "finalOpinion") ?
                      item.finalDiagnosis.diagName :
                      0
            )

        }
        return (aItem, bItem) =>compareValues(sortValue(aItem, key), sortValue(bItem, key)) * (direction==='desc'? -1: 1)
    }

    const clickCase = (caseId,caseData) =>{
        if(caseData){
            //console.log("caseData",caseData)
            props.prorodinkiService.caseList = caseData
        }
        props.history.push(`/case/${caseId}`)
    }


    const onSort = (value) =>{
        //console.log("Start onSort", value, order, "isLoading: ", table.isLoading)
        let direction = value.direction
        if (order[0] === value.column ) {
          direction = order[1] === "asc" ? "desc" : "asc"
        }

        setOrder([value.column, direction])

        setTable({...table, data: props.prorodinkiService.caseList.sort(compareData(value.column, direction))})

        initialParams.order = [value.column, direction]
        localStorage.setItem('CaseListParams', JSON.stringify(initialParams));

    }
    const setDiagnoseSeries = async (caseIds, diagnosis) => {
        //console.log("setDiagnoseSeries caseIds",caseIds)
        //console.log("setDiagnoseSeries diagnosis",diagnosis)
        setTable({...table,...{data:[],isLoading:true}})
        await props.prorodinkiService.putDiagnoseSeries(caseIds, diagnosis);
        setForceReload(!forceReload)
    }
    const setAgreeWithNetworkDiagnose = async (caseIds) => {
        //console.log("setDiagnoseSeries caseIds",caseIds)
        //console.log("setDiagnoseSeries diagnosis",diagnosis)
        setTable({...table,...{data:[],isLoading:true}})
        await props.prorodinkiService.putAgreeNetworkDiagnoseSeries(caseIds);
        setForceReload(!forceReload)
    }
    const setAgreeWithExpertDiagnose = async (caseIds) => {
        //console.log("setDiagnoseSeries caseIds",caseIds)
        //console.log("setDiagnoseSeries diagnosis",diagnosis)
        setTable({...table,...{data:[],isLoading:true}})
        await props.prorodinkiService.putAgreeExpertDiagnoseSeries(caseIds);
        setForceReload(!forceReload)
    }

    const period = {dateRange:dateRange,  onChange: changeDateRange};

    return (
        <CaseList2 table={table}
                  clickEvent={clickCase}
                  period={period} order={order}
                  entries={{selectedEntries:selectedEntries, pageEntries:pageEntries, onEntriesChanged:changeEntries}}
                  onChangeFilter={changeStatusFilter}
                  statusFilter = {statusFilter}
                  onSort={onSort}
                   requestMode={{mode:requestMode,handler:changeRequestMode,count:props.prorodinkiService.orders}}
                   setDiagnose={setDiagnoseSeries}
                   diagnoseSameAs={{network:setAgreeWithNetworkDiagnose, expert:setAgreeWithExpertDiagnose}}
                   dataPage={{page:page,setPage:setPage}}
        />
    )

}

export default withRouter(withProrodinkiService(CaseListController))

