import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Case from '../../pages/case';
import CaseListController from '../../pages/CaseList';
import LoginCard from "../../pages/logincard"
import Verification from "../../pages/verification"
import CaseController from "../../pages/case/CaseController";
import ConsultationController from "../../pages/Consultation/ConsultationController";
import ExamController from "../../pages/Exam/ExamController";
import VerificationController from "../../pages/verification/VerificationController";
import Arbitrage from "../../pages/arbitrage"
import ArbitrageController from "../../pages/arbitrage/ArbitrageController"
import MarkObjects from "../../pages/markobjects";

class Routes extends React.Component {

  render() {
    return (
      <Switch>
        <Route exact path="/" render= {this.props.hasPermission('CASELIST')?
          ()=><CaseListController checkMode={'simple'}/>:
          ()=><Redirect to={{ pathname: this.props.mainPath}}  />}
        />

        <Route exact path="/validation" render= {this.props.hasPermission('VALIDATION')?
          ()=><CaseListController checkMode={'validation'}/>:
          ()=><Redirect to={{ pathname: this.props.mainPath}}  />}
        />
        <Route exact path="/case/:caseId" render={this.props.hasPermission('CASE')?
          ({match}) =>{
            const {caseId} = match.params;
            return( <CaseController caseId={caseId} />)
            }:
          ()=><Redirect to={{ pathname: this.props.mainPath}}  />}
        />

        <Route exact path="/login" component={LoginCard} />

        <Route exact path="/verification" render= {this.props.hasPermission('VERIFICATION')?
            ()=><VerificationController/>:
            ()=><Redirect to={{ pathname: this.props.mainPath}}  />}
        />
          <Route exact path="/arbitrage/:caseId" render= {this.props.hasPermission('VERIFICATION')?
              ({match}) =>{
                  const {caseId} = match.params;
                  //console.log('ArbitrageCase', caseId)
                  return( <ArbitrageController caseId={caseId} />)
              }:
              ()=><Redirect to={{ pathname: this.props.mainPath}}  />}
          />
          <Route exact path="/arbitrage" render= {this.props.hasPermission('VERIFICATION')?
              ()=><Arbitrage/>:
              ()=><Redirect to={{ pathname: this.props.mainPath}}  />}
          />
        <Route exact path="/markobjects" render= {this.props.hasPermission('MARKUPS')?
            ()=><MarkObjects/>:
            ()=><Redirect to={{ pathname: this.props.mainPath}}  />}
        />
        <Route exact path="/consultation" render= {this.props.hasPermission('CONSULTATION')?
            ()=><ConsultationController/>:
            ()=><Redirect to={{ pathname: this.props.mainPath}}  />}
        />
        <Route exact path="/exam" render= {this.props.hasPermission('EXAM')?
            ()=><ExamController/>:
            ()=><Redirect to={{ pathname: this.props.mainPath}}  />}
        />
        <Route exact path="/admin" render={this.props.hasPermission('ADMINISTRATOR')?
            ()=><h1>Страница Администратора пока неготова :( </h1>:
            ()=><Redirect to={{ pathname: this.props.mainPath}}  />}
        />
        <Route exact path="/profile" render={function () {
          return <h1>Страница профиля пользователя пока неготова :( </h1>;
        }} />
        <Redirect from="/signout" exact to={this.props.mainPath} />
          <Route exact path="/payment/status" render={function () {
              return <h1>Платеж обрабатывается... </h1>;
          }} />
        <Route
          render={function () {
            return <h1>Not Found</h1>;
          }}
        />
      </Switch>
    );
  }
}

export default Routes;
