export function compareValues(a, b) {

  const varA = (typeof a === 'string') ?
    a.toUpperCase() : a;
  const varB = (typeof b === 'string') ?
    b.toUpperCase() : b;

  let comparison = 0;
  if (varA > varB) {
    comparison = 1;
  } else if (varA < varB) {
    comparison = -1;
  }
  return comparison
};

export function subtractMonthes(d, monthDiff){
  let day = d.getDate()
  d.setFullYear(d.getFullYear(), d.getMonth()-monthDiff, day)
  if(d.getDate() === day){
    d.setFullYear(d.getFullYear(), d.getMonth(), day+1)
  }
}