import React, {Component, Fragment} from "react";
import withProrodinkiService from "../../components/hoc/with-prorodinki-service";
import {baseUrl} from "../../config"
import {
    MDBBtn,
    MDBCol,
    MDBRow,
    MDBContainer,
    MDBCard,
    MDBCardBody,
    MDBIcon,
    MDBCardImage,
    MDBCardTitle,
    MDBCardText,
    MDBDataTable,
    MDBBtnGroup,
    MDBCardHeader
} from "mdbreact"
import {Link } from 'react-router-dom';
import ErrorCheck from "../../components/error-check";
import {Diagnoses} from "../../components/diagnoses"
import DiagnoseButtons from "../../components/DiagnoseButtons";

const Exam = ({testNumber,
                testLength,
                error,
                readOnly,
                selectedDiagnose,
                diagnoseClick,
                imageSource ,
                setDiagnose}) =>{
    return (
        <MDBContainer fluid className="py-5" >
            <ErrorCheck error={error.isError} message={error.errorMessage}>
                <MDBRow>
                    <MDBCol md="6">
                        <MDBCard className="text-center" style={{backgroundColor: `${selectedDiagnose.background}`}}>
                            <MDBCardTitle className="pt-3">
                                {`Тест ${testNumber+1} из ${testLength}`}
                            </MDBCardTitle>
                            {   imageSource===null? null:
                                <MDBCardImage
                                  className="pl-3 mb-5 img-fluid"
                                  src={imageSource}
                                />
                            }
                        </MDBCard>
                    </MDBCol>
                    <MDBCol md="6">
                        <MDBContainer className="text-left">
                            <h5> Ваше заключение: </h5>
                            <MDBBtnGroup vertical size="sm">
                                <DiagnoseButtons selectedDiagnose={selectedDiagnose}
                                                 readOnly = {readOnly}
                                                 diagnoseClick ={diagnoseClick} />
                            </MDBBtnGroup>
                        </MDBContainer>
                        <MDBContainer className="mt-4 text-left">
                            <MDBBtnGroup>
                                <MDBBtn color="primary" disabled={selectedDiagnose.id === null} onClick={setDiagnose}>
                                    {readOnly? "Результаты": "Подтвердить"} <MDBIcon icon="check" className="ml-1"/>
                                </MDBBtn>
                            </MDBBtnGroup>
                        </MDBContainer>
                    </MDBCol>
                </MDBRow>
            </ErrorCheck>
        </MDBContainer>
    );

};
export default Exam