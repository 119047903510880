import React, {Component, Fragment, useEffect, useState} from "react";
import withProrodinkiService from "../../components/hoc/with-prorodinki-service";
import {baseUrl} from "../../config"
import Exam from "./Exam";
import {MDBIcon} from "mdbreact";
import {Diagnoses} from "../../components/diagnoses";
import ExamResults from "./ExamResults";

const ExamController = props =>{

  const initialDiagnose = {id: null, background:"#FFFFFF"}

  const [examData, setExamData] = useState(null)
  const [loadingStatus, setLoadingStatus] = useState(true)
  const [error, setError] = useState({isError:false, errorMessage:null})
  const [selectedDiagnose, setSelectedDiagnose] = useState(initialDiagnose)
  const [testNumber, setTestNumber] = useState(0)
  const [showResults, setShowResults] = useState(false)

  useEffect( () => {
      async function getExamFromServer()
      {
        const response = await(props.prorodinkiService.getExam());
        processResponse(response)
      }
      if(!showResults) {
        getExamFromServer();
      }
    },
      [showResults]
  )

  const setDiagnose =  () => {
    examData[testNumber].examDiagnosis = selectedDiagnose;
    if(testNumber < (examData.length-1)){
      setTestNumber(testNumber+1)
      setSelectedDiagnose(initialDiagnose)
    }
    else{
      setShowResults(true)
    }
  }

  const processResponse  = (response) => {
    setLoadingStatus(false)
    if (!response.hasError && response.data === "") {
      response.hasError = true
      response.error.message = "Данные не обнаружены"
    }
    if (response.hasError) {
      setError({isError:true, errorMessage:response.error.message})
      setSelectedDiagnose({id: null, background:null})
      setExamData(null)
      // console.log('Error returned:', response.error)
    } else {
      setLoadingStatus(false)
      setError({isError:false, errorMessage:null})
      setSelectedDiagnose(initialDiagnose)
      setExamData(response.data)
      setTestNumber(0);
      setShowResults(false)
    }
    return (!response.hasError)
  }

  const newExamClick = () =>{
    setLoadingStatus(true)
    setError({isError:false, errorMessage:null})
    setSelectedDiagnose(initialDiagnose)
    setExamData(null)
    setShowResults(false)
  }



  const diagnoseClick = (item) => {
    setSelectedDiagnose(item)
    // console.log(item)
  }


  // console.log("examData", examData, "testNumber", testNumber)
  if (showResults) {
    window.scrollTo(0, 0);
    return (<ExamResults examData={examData} newExamClick ={newExamClick} />)
  }
  else {
    return (
        <Exam testNumber={testNumber}
              testLength={examData === null ? 0 : examData.length}
              isLoading={loadingStatus}
              error={error}
              readOnly={false}
              selectedDiagnose={selectedDiagnose}
              diagnoseClick={diagnoseClick}
              imageSource={examData === null ? null : `${baseUrl}/img/${examData[testNumber].imageName}`}
              setDiagnose={setDiagnose}
        />
    )
  }
}

export default withProrodinkiService(ExamController)
