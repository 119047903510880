import {Diagnoses} from "./diagnoses";
import {MDBBtn, MDBBtnGroup, MDBCol} from "mdbreact";
import React from "react";

const DiagnoseButtons = ({selectedDiagnose, readOnly, diagnoseClick}) =>{
  return (
      <MDBBtnGroup vertical size="sm">
          {Diagnoses.filter(diag=>diag.type!=='UNDEFINED').map(item => (
          <MDBBtn key={item.id} outline={item.id !== selectedDiagnose.id}
                  className="mt-3" color={readOnly? "#111111": item.color} onClick={() => {
            diagnoseClick(item)
             }} disabled={readOnly}>
            <strong>{item.name}</strong>
          </MDBBtn>
        ))}
      </MDBBtnGroup>
  )
}

export default DiagnoseButtons
