import * as React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';

import {useRef,useEffect,useState} from "react";
import Box from "@mui/material/Box";

import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Badge from '@mui/material/Badge';

import UserTablePagination from "./UserTablePagination";
import {Diagnoses} from "../../components/diagnoses";
import {ImageItem} from "./ImageItem";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";


export default function CaseImageList({imageData,onImageClick,setDiagnose, diagnoseSameAs, dataPage, resetChecked}) {
    const imgWidth=200
    const listRef=useRef()
    const getCols = (width)=>parseInt((width-100)/(imgWidth+50))
    const [imageListCols, setImageListCols] = useState(getCols(window.innerWidth));
    const getNewSize = () => {
        setImageListCols(getCols(window.innerWidth))
        // console.log("imageListCols",getCols(window.innerWidth))
    };
    const [diagnosis, setDiagnosis] = React.useState(-1);

    const handleDiagnosisChange = (event) => {
        setDiagnosis(event.target.value);
    }
    useEffect(() => {
        window.addEventListener("resize", getNewSize);
    }, []);

    const initialRowsPerPage= !localStorage.getItem("ImageListRowsPerSize")?10:parseInt(localStorage.getItem("ImageListRowsPerSize"))

    //const [page, setPage]=useState(initialPage)
    const [rowsPerPage, setRowsPerPage]=useState(initialRowsPerPage)

    const onPageChange = (event, newPage) => {
        //console.log("newPage",newPage)
        //resetChecked(imageData)
        dataPage.setPage(newPage)
    };

    const diagNames=[]
    Diagnoses.forEach(diag=> diagNames[diag.id]=diag.short)

    const onRowsPerPageChange = ( event) => {
        localStorage.setItem("ImageListRowsPerSize", event.target.value.toString())
        setRowsPerPage(event.target.value)
    };
    const [checkedItems, setCheckedItems]=useState(0)
    const countCheckedItems = ()=>imageData.filter(el=>!!el.checkState).length
    const onCheckChange = ()=> {setCheckedItems(countCheckedItems())}
    const setCheckedDiagnoses = ()=>{
        setDiagnose(imageData.filter(el=>!!el.checkState).map(el=>el.id),Diagnoses[diagnosis].id)
    }
    const agreeWithAIDiagnoseChecked = ()=>{
        diagnoseSameAs.network(imageData.filter(el=>!!el.checkState).map(el=>el.id))
    }
    const agreeWithExpertDiagnoseChecked = ()=>{
        diagnoseSameAs.expert(imageData.filter(el=>!!el.checkState).map(el=>el.id))
    }
    const pageData = ()=> imageData.slice(Math.min(imageData.length,rowsPerPage*(dataPage.page)), Math.min(imageData.length, rowsPerPage*(dataPage.page+1)))
    const isAllChecked = ()=> {
        return pageData().filter(el=>el.orderState !== "OPEN").length>0 && !(pageData().filter(el=>el.orderState !== "OPEN").find(el=>!el.checkState))
    }
    const checkAllOnPage = (e)=>{
        pageData().filter(el=>el.orderState !== "OPEN").forEach(el=>el.checkState=e.target.checked)
        setForceRender(!forceRender)
        console.log("pageData",pageData())
    }
    const [forceRender, setForceRender]= useState(false)
    //console.log("CaseImageList imageData",imageData)
    return (
        <Box sx={{ mx:5}}>
            <Box sx={{mx:5, display:'flex', flexDirection:'row', alignItems:"flex-end", alignContent: 'flex-end'}}>
                <FormControlLabel
                    checked={isAllChecked()}
                    value="fullPageChecked"
                    control={<Checkbox color="primary" />}
                    onChange={checkAllOnPage}
                    label= 'Все'
                    labelPlacement="end"
                />
                <FormControl variant="standard" sx={{ m: 1, minWidth: 200 }}>
                    <InputLabel id="select-diagnose-label">Диагноз</InputLabel>
                    <Select
                        labelId="select-diagnose-label"
                        id="select-diagnose"
                        value={countCheckedItems()===0?-1:diagnosis}
                        onChange={handleDiagnosisChange}
                        label="Диагноз"
                        disabled={countCheckedItems()===0}
                    >
                        <MenuItem key={-1} value={-1}>
                            <em>None</em>
                        </MenuItem>
                        {   Diagnoses.filter(diag=>diag.id!=='UNDEFINED').map((diag,index)=>(
                            <MenuItem key={index} value={index}>{diag.name}</MenuItem>))
                        }
                    </Select>
                </FormControl>

                <Badge badgeContent={countCheckedItems()} color="primary">
                    <Button sx={{ m: 1, minWidth: 100 }} style={{textTransform: 'capitalize'}} color='primary' variant="outlined" size={'small'}
                            disabled={ diagnosis<0} onClick={setCheckedDiagnoses}
                    >
                        {'Отправить'}
                    </Button>
                </Badge>
                {/*<Typography sx={{ m: 1, minWidth: 100 }} style={{fontSize: 14, fontStyle: 'italic'}} variant="subtitle1">{'Согласиться с'}</Typography>*/}
                <Button sx={{ my:1, minWidth: 120 }} style={{textTransform: 'capitalize'}} color='primary' variant="outlined" size={'small'}
                        disabled={ countCheckedItems()<=0} onClick={agreeWithAIDiagnoseChecked}
                >
                    {'Как нейросеть'}
                </Button>
                <Button sx={{ m:1, minWidth: 120 }} style={{textTransform: 'capitalize'}} color='primary' variant="outlined" size={'small'}
                        disabled={ countCheckedItems()<=0} onClick={agreeWithExpertDiagnoseChecked}
                >
                    {'Как эксперт'}
                </Button>
                {dataPage.page * rowsPerPage >= imageData.length ? null :
                    <UserTablePagination
                        count={imageData.length}
                        page={dataPage.page}
                        onPageChange={onPageChange}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={onRowsPerPageChange}
                    />
                }
            </Box>
            <ImageList sx={{ width: "100%",  mx:5, mt:3, height: '100%',overflow: 'hidden'}} cols={imageListCols} ref={listRef}>
                {pageData().map((image) => (
                    <ImageListItem sx={{width:imgWidth, height:imgWidth, maxHeight: imgWidth+50}} key={image.imagePath}>
                        <Card>
                            <ImageItem item={image} imgWidth={imgWidth} onImageClick={onImageClick} onCheckChange ={onCheckChange} />
                        </Card>
                        <ImageListItemBar
                            title={<span>{`${diagNames[image.humanDiagData.diagnosis]}/${diagNames[image.neuralDiagnosis.diagnosis]}`}</span>}
                            subtitle={<span >{`${image.id}; ${new Date(image.caseDate).toLocaleDateString('ru-RU',{hour:'2-digit', minute:'2-digit'})}`}</span>}
                            position="below"

                        />
                    </ImageListItem>
                ))}
            </ImageList>
            <Box sx={{mr:10}}>
                {dataPage.page * rowsPerPage >= imageData.length ? null :
                    <UserTablePagination
                        count={imageData.length}
                        page={dataPage.page}
                        onPageChange={onPageChange}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={onRowsPerPageChange}
                    />
                }
            </Box>
        </Box>
    );
}

