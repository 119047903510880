import React, { Component } from 'react';

const ErrorCheck = (props)=>{
      if (props.error) {
        return (<h3> {props.message} </h3>)
      }
      else{
        return (props.children);
      }
}
export default ErrorCheck