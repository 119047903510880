import React, {Component, Fragment, useState} from "react";
import withProrodinkiService from "../../components/hoc/with-prorodinki-service";
import { Button} from '@mui/material';
import {
    MDBBtn,
    MDBCol,
    MDBRow,
    MDBContainer,
    MDBCard,
    MDBCardBody,
    MDBIcon,
    MDBCardTitle,
    MDBDataTable,
    MDBBtnGroup,
} from "mdbreact"
import ErrorCheck from "../../components/error-check";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import {Diagnoses} from "../../components/diagnoses"
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import {Layer, Rect, Stage} from "react-konva";
import MouseTooltip from 'react-sticky-mouse-tooltip';
import useResizeObserver from '@react-hook/resize-observer'
import SignGroups2 from "./SignGroups2";
import Recommendation from "./Recommendation";
import ConclusionTable from "./ConclusionTable";
import Typography from "@mui/material/Typography";
import Evidence from "./Evidence";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import DiagnoseButtons from "../../components/DiagnoseButtons";



const Case = ({title, opinions, caseId, caseIndex, selectedDiagnose, error, attributes, patientProps,
                  diagnoseClick, images, expertOpinion, secondaryControls, evidence, tabs,
                  noMessage, onChange, setDiagnose, isolate, neighborLink, exclusive, nnProps, getNnResults}) => {
    const useSize = (target) => {
        const [size, setSize] = React.useState()

        React.useLayoutEffect(() => {
            // console.log('target.current',target.current)
            if(!!target.current)  setSize(target.current.getBoundingClientRect())
        }, [target])

        // Where the magic happens
        useResizeObserver(target, (entry) => setSize(entry.contentRect))
        // console.log('size',size)
        return !!size?size:{width:0,height:0,x:0,y:0}
    }
    const {tabsValue, setTabsValue}={...tabs}
    const [showNnResults, setShowNnResults] = useState(-1)
    const target = React.useRef(null)
    const size = useSize(target)

    const confirmation = () => {
        return window.confirm("Ваше заключение не будет сохранено!")
    }
    const confirmIsolation = () => {
        return window.confirm("Обращение будет перенесено из записи о представленном новообразовании в специально созданную запись о новом новообразовании.")
    }


    const handleTabsChange =(event, value)=>{
        setTabsValue(value)
    }
    return <>
        <ErrorCheck error={error.isError} message={error.errorMessage}>
            <MDBRow className="py-5">
                <MDBCol className="pl-5" md="4">
                    {/*<SectionContainer header="Basic example" className="justify-content-center d-flex">*/}
                    <MDBCard className="text-center" style={{backgroundColor: `${selectedDiagnose.background}`}}>
                        <MDBCardTitle className="pt-3">
                            {title}
                        </MDBCardTitle>
                        <MDBCardBody className='text-center'>
                            {!!(getNnResults().caseObjects) && getNnResults().caseObjects.length>0?
                                <div ref={target} >
                                    <div  style={{position:'absolute', zIndex: 100}}>
                                        <Stage width={size.width} height={size.width}>
                                            <Layer>
                                                {
                                                    getNnResults().caseObjects.map((rect, index) =>
                                                        <Rect
                                                            key={index}
                                                            x={rect.leftX * size.width}
                                                            y={rect.topY * size.width}
                                                            width={(rect.rightX - rect.leftX) * size.width}
                                                            height={(rect.bottomY - rect.topY) * size.width}
                                                            stroke={"green"}
                                                            onMouseOver={()=>setShowNnResults(index)}
                                                            onMouseOut={()=>setShowNnResults(-1)}
                                                        />)
                                                }
                                            </Layer>
                                        </Stage>
                                    </div>
                                </div>:null}
                            <div className="carousel-wrapper px-1">
                                <Carousel infiniteLoop
                                          useKeyboardArrows
                                          onChange = {index => onChange(index) }
                                          selectedItem = {caseIndex}
                                >
                                    {images.map( el =>
                                        { return(
                                            <div key={el}>
                                                <img src = {el.src} />
                                                {/*<p className="legend">{el.diagName}</p>*/}
                                            </div>)
                                        }
                                    )}
                                </Carousel>
                            </div>
                            {showNnResults>=0?
                                <div className="amber-text">
                                    <MouseTooltip
                                        visible={showNnResults>=0}
                                        offsetX={15}
                                        offsetY={10}
                                    >
                                        <span>{getNnResults().caseObjects[showNnResults].nnDiagnosisList.map(el=>el.diagName+':'+el.confidence.toFixed(2)).join(',')}</span>
                                    </MouseTooltip>
                                </div>:null}

                            <Button color="primary" className="pt-3" disabled={images.length < 2} onClick={() => {
                                let call = true
                                if (selectedDiagnose.id !== null) {
                                    call = confirmation();
                                }
                                if (call) {
                                    call = confirmIsolation()
                                    if (call) {
                                        isolate.onClick()
                                    }
                                }
                            }}>
                                Перенести из новообразования
                            </Button>
                        </MDBCardBody>
                    </MDBCard>
                    {/*</SectionContainer>*/}
                </MDBCol>
                <MDBCol md="6">
                    <MDBContainer className="text-left">
                        <MDBRow>
                            <MDBCol md="3" className={"mt-4"}>
                                <DiagnoseButtons selectedDiagnose={selectedDiagnose}  diagnoseClick={diagnoseClick}/>
                            </MDBCol>
                            <MDBCol md="4">
                                <Box sx={{ minWidth: 800 }}>
                                    {expertOpinion.isRequired()?
                                        <Typography style={{fontSize: 14, fontStyle: 'italic'}} color = 'rgba(255, 0, 0, 0.7)' variant='string'
                                                    component="div">
                                            Мнение эксперта обязательно.
                                        </Typography>:null
                                    }
                                    <TabContext value={tabsValue}>
                                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                            <TabList value={tabsValue} onChange={handleTabsChange} aria-label="lab API tabs">
                                                <Tab label="Информация" value={'conclusions'} />
                                                <Tab label="Анализ изображения" value={'analyse'} disabled = {!expertOpinion.isEnabled()}/>
                                                <Tab label="Рекомендация" value={'recommendation'} disabled = {!expertOpinion.isEnabled()}/>
                                            </TabList>
                                        </Box>
                                        <TabPanel value={'conclusions'} index={0}>
                                            <ConclusionTable sx={{ mt: 2}} opinions = {opinions} attributes={attributes} patientProps={patientProps}/>
                                        </TabPanel>
                                        <TabPanel value={'analyse'} index={1}>
                                            <SignGroups2 sx={{ mt: 2}} evidence = {evidence}/>
                                        </TabPanel>
                                        <TabPanel value={'recommendation'} index={2}>
                                            <Recommendation expertOpinion={expertOpinion} secondaryControls={secondaryControls} selectedDiagnose={selectedDiagnose} noMessage={noMessage}/>
                                        </TabPanel>
                                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                            <TabList value={tabsValue} onChange={handleTabsChange} aria-label="lab API tabs">
                                                <Tab label="Информация" value={'conclusions'} />
                                                <Tab label="Анализ изображения" value={'analyse'} disabled = {!expertOpinion.isEnabled()}/>
                                                <Tab label="Рекомендация" value={'recommendation'} disabled = {!expertOpinion.isEnabled()}/>
                                            </TabList>
                                        </Box>
                                    </TabContext>
                                </Box>
                                {/*{getSignsText().map((text,i)=>(*/}
                                {/*    <Typography key={i} style={{fontSize: 14,minWidth: 500 }} variant='string' component="div">*/}
                                {/*        {text}*/}
                                {/*    </Typography>))*/}
                                {/*}*/}

                            </MDBCol>
                        </MDBRow>
                    </MDBContainer>
                     <MDBContainer className="mt-2 text-right">
                        <FormControlLabel
                            checked={exclusive.mode}
                            control={<Checkbox color="primary"/>}
                            onChange={exclusive.change}
                            label="Диагностирование"
                            labelPlacement="end"
                        />
                        <FormControlLabel
                            checked={nnProps.nnSuitable}
                            control={<Checkbox color="primary"/>}
                            onChange={nnProps.changeNnSuitable}
                            label="Для обучения нейросети"
                            labelPlacement="end"
                        />
                    </MDBContainer>
                    <MDBContainer className="mt-2 text-right">
                        <MDBBtnGroup className={"align-items-center"}>
                            {process !== "VERIFICATION" ?
                                <MDBIcon
                                    icon="arrow-left" size="2x" className="mr-3 text-primary"
                                    onClick={() => {
                                        let call = true
                                        if (selectedDiagnose.id !== null) {
                                            call = confirmation();
                                        }
                                        if (call) {
                                            neighborLink(-1)
                                        }
                                    }}
                                />:null
                            }
                            <MDBBtn color="primary" size={"sm"} disabled={selectedDiagnose.id === null}
                                    onClick={() => setDiagnose(caseId,selectedDiagnose.id, 1)}>
                                Отправить <MDBIcon icon="check" />
                            </MDBBtn>

                            <MDBIcon
                                icon="arrow-right" color="blue" size="2x" className="ml-2  text-primary"
                                onClick={() => {
                                    let call = true
                                    if (selectedDiagnose.id !== null) {
                                        call = confirmation();
                                    }
                                    if (call) {
                                        neighborLink(1)
                                    }
                                }}
                            />
                        </MDBBtnGroup>
                    </MDBContainer>
                </MDBCol>
            </MDBRow>
        </ErrorCheck>
    </>
};
export default Case
