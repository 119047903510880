import React, {Component, Fragment, useEffect, useState} from "react";
import withProrodinkiService from "../../components/hoc/with-prorodinki-service";
import {baseUrl} from "../../config"
import ArbitrageCase from "./ArbitrageCase";
import {MDBIcon} from "mdbreact";

const ArbitrageController = props =>{
    const initialDiagnose = {id: null, background:"#888888"}

    const [neoplasmData, setNeoplasmData] = useState(null)
    const [loadingStatus, setLoadingStatus] = useState(true)
    const [error, setError] = useState({isError:false, errorMessage:""})
    const [selectedDiagnose, setSelectedDiagnose] = useState(initialDiagnose)
    const [newCaseTrigger, setNewCaseTrigger] = useState(false)
    const [caseId, setCaseId] = useState(parseInt(props.caseId))
    const [currentIndex, setCurrentIndex] = useState(props.caseId)

    const getCaseFromServer = async  () =>
    {
        setLoadingStatus(true)

        let response = await (props.prorodinkiService.getCase(caseId, false));

        processResponse(response)
    }

    useEffect(  () => {
            getCaseFromServer().then(()=> {} )
        },[caseId]
    )

    const setDiagnose = async () => {
        // console.log('setDiagnose ', selectedDiagnose)
        // console.log('arbitrageList before ', props.prorodinkiService.arbitrageList)
        // console.log('caseId before ', caseId)
        if(!!selectedDiagnose.id) {
            const response = await (props.prorodinkiService.putArbitrageDiagnose(caseId, selectedDiagnose.id));
            if (response.hasError) {
                setError({isError: true, errorMessage: response.error.message})
                setSelectedDiagnose({id: null, background: null})
                setNeoplasmData(null)
            } else {
                // console.log('arbitrageList after ', props.prorodinkiService.arbitrageList)
                // console.log('length ',props.prorodinkiService.arbitrageList.length)
                const nextIndex = Math.min(props.prorodinkiService.arbitrageList.findIndex(el => (el.caseId === caseId)) + 1, props.prorodinkiService.arbitrageList.length - 1)
                // console.log('nextIndex ', nextIndex)
                if (nextIndex >= 0) {
                    // console.log('nextCaseId ', props.prorodinkiService.arbitrageList[nextIndex].caseId)
                    setCaseId(props.prorodinkiService.arbitrageList[nextIndex].caseId);
                    setLoadingStatus(true)
                }
            }
        }
    }

    const processResponse  = (response) => {
        if (!response.hasError && response.data === "") {
            response.hasError = true
            response.error.message = "Данные не обнаружены"
        }
        if (response.hasError) {
            setError({isError:true, errorMessage:response.error.message})
            setSelectedDiagnose({id: null, background:null})
            setNeoplasmData(null)
            //console.log('Error returned:', response.error)
        } else {
            // console.log('processResponse Response:', response)
            setError({isError:false, errorMessage:null})
            setSelectedDiagnose(initialDiagnose)
            setCurrentIndex(response.data.currentIndex)
            setNeoplasmData(response.data)
        }
        setLoadingStatus(false)

        return (!response.hasError)
    }

    const attributes = () => {
        return ({
            columns: [
                {
                    label: "Возраст",
                    field: "age",
                },
                {
                    label: "Пол",
                    field: "sex",
                },
                {
                    label: "Фототип",
                    field: "phototype",
                },

                {
                    label: "Ожоги",
                    field: "burns",
                },
                {
                    label: "Локализация",
                    field: "location",
                },
                {
                    label: "Когда возникло",
                    field: "beginning",
                },
            ],
            rows: neoplasmData===null? []: [
                {
                    age: new Date().getFullYear() - neoplasmData.patient.year,
                    sex: neoplasmData.patient.sex? "Женский": "Мужской",
                    photoType: neoplasmData.patient.photoType,
                    burns:neoplasmData.patient.wereSunburn? "Были": "Не было",
                    location: neoplasmData.location.description,
                    beginning: neoplasmData.beginning.description
                },

            ],
        })};
    const opinions = () => ({
        columns: [
            {
                label: "Эксперт",
                field: "name",
            },
            {
                label: "Диагноз",
                field: "Diagnose",
            },
        ],
        rows: neoplasmData===null? [] :neoplasmData.caseImages[currentIndex].opinions.map(item =>(
            {
                name: item.expert.fio,
                Diagnose: item.diag.diagName,
            })
        )
    });
    const diagnoseClick = (item) => {
        setSelectedDiagnose(item)
        // console.log('afterDiagnoseClick', item)
    }


    const images = (!neoplasmData || !(neoplasmData.caseImages) || !(neoplasmData.caseImages[currentIndex])) ? []:
        [{
            src:`${baseUrl}/img/${neoplasmData.caseImages[currentIndex].filename}`,
            diagName: "",
        }]

    // console.log("Verification render", neoplasmData, currentIndex)
    return (  neoplasmData===null || loadingStatus? null:
        <ArbitrageCase    caseId             = {neoplasmData.caseImages[neoplasmData.currentIndex].id}
                          caseIndex          = {0}
                          error             ={error}
                          selectedDiagnose  ={selectedDiagnose}
                          attributes        ={attributes}
                          diagnoseClick     ={diagnoseClick}
                          images            = {images}
                          setDiagnose       = {setDiagnose}
                          opinions          ={opinions}
        />)
}

export default withProrodinkiService(ArbitrageController)
